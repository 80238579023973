const teaserStyle = theme => ({
  subText: {
    margin: "1rem 0",
  },
  textContent: {
    [theme.breakpoints.down('md')]: {
      textAlign: "center",
    },
  },
  button: {
    minWidth: 200,
  },
  smallImage: {
    width: 200,
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
});

export default teaserStyle;
