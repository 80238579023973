import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkEmail, register } from "../../../store/login/actions";
import { getLpByLocation } from "../../../services/landingPagesList";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import trafficCheckinStyle from "./trafficCheckinStyle";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FormProvider } from "../../../provider/Form/FormProvider";
import TrafficCheckinForm from "./TrafficCheckinForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";

const useStyles = makeStyles(trafficCheckinStyle);

export default function TrafficCheckinPartnerInsurer({ accusation, partner, target, idPrefix, checkinHeading, color }) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    accusationDetail: "",
    caseReferenceNumber: "",
    email: "",
  };

  const [emailErrorText, setEmailErrorText] = useState("");

  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource);
  const updateMutation = useMutation(updateResource);

  const handleSubmit = async ({ values }) => {
    setIsLoading(true);
    const emailCheck = await checkEmail(values.email.toLowerCase());
    if (emailCheck.hasError) {
      setIsLoading(false);
      setEmailErrorText(emailCheck.errorText);
      return;
    }

    const landingPage = getLpByLocation(location.pathname) || { keyword: location.pathname };
    const data = {
      email: values.email.toLowerCase(),
      gclid: localStorage.getItem("gclid"),
      prelead: {
        initialProduct: "traffic",
        accusation: accusation.name,
        accusationDetail: {
          [accusation.detail.name]: values.accusationDetail,
        },
        landingPage: landingPage.keyword,
        partner: partner,
      },
    };

    const { from } = location.state || { from: { pathname: target } };
    await register(data);
    const product = await createMutation.mutateAsync({ uri: "/traffic", data: { acquisitionPartner: partner } });
    await updateMutation.mutateAsync({
      uri: "/customer_insurances",
      id: product.insurance.id,
      data: { caseReferenceNumber: values.caseReferenceNumber },
    });
    await queryClient.invalidateQueries(queryKeys.item("/traffic", product.id));
    await queryClient.invalidateQueries(queryKeys.collection("/customers"));
    navigate(from.pathname);
  };

  return (
    <Paper elevation={4} className={classNames(classes.formPaper, classes[color])}>
      <div id={idPrefix + "-checkin"} className={classes.scrollToAnchor} />
      <Typography variant={"h4"} className={classes.heading}>
        {checkinHeading}
      </Typography>
      <FormProvider initialValues={initialValues} submitAction={handleSubmit}>
        <TrafficCheckinForm
          partnerInsurer={partner}
          accusation={accusation}
          isLoading={isLoading}
          emailErrorText={emailErrorText}
          setEmailErrorText={setEmailErrorText}
        />
      </FormProvider>
    </Paper>
  );
}

TrafficCheckinPartnerInsurer.propTypes = {
  partner: PropTypes.string.isRequired,
  idPrefix: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["default", "grey"]),
  checkinHeading: PropTypes.string.isRequired,
  target: PropTypes.string,
  accusation: PropTypes.shape({
    name: PropTypes.string.isRequired,
    detail: PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }).isRequired
      ),
    }).isRequired,
  }).isRequired,
};

TrafficCheckinPartnerInsurer.defaultProps = {
  color: "default",
  checkinHeading: "Wir prüfen Ihren Bescheid kostenlos! Was wird Ihnen vorgeworfen?",
  target: "/servicewelt",
  accusation: {
    name: "accusation",
    detail: {
      name: "accusation",
      label: "Vorwurf auswählen",
      options: [
        { value: "speeding", label: "Geschwindigkeitsverstoß" },
        { value: "redLight", label: "Rotlichtverstoß" },
        { value: "distance", label: "Abstandsverstoß" },
        { value: "other", label: "Handy / Drogen / Parken / Sonstiges" },
      ],
    },
  },
};
