/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import { Grid } from "@mui/material";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { getLabelTranslationPath, getQuestionTranslationPath, translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import {floatFieldValidator, numberFieldDefault, requiredValidator} from "../../../../services/validationRules";
import React, {useEffect} from "react";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";
import {ValidatorType} from "../../../../types/ValidatorType";
import {ThousandsNumberInput} from "../../../FormFields/MaskedInputs/ThousandsNumberInput";

type NumberFieldConfiguredProps = {
  caseData: Object;
  fieldPath: string;
  required?: boolean | undefined;
  numberType?: "float" | "int";
  labelDependsOnField?: string | null;
  questionDependsOnField?: string | null;
};
export default function NumberFieldConfigured(
  {
    caseData,
    fieldPath,
    required,
    numberType = "int",
    labelDependsOnField = null,
    questionDependsOnField = null,
  }: NumberFieldConfiguredProps) {
  const helperText = translateHelperTextIfExists(caseData, fieldPath);
  const question = translate(getQuestionTranslationPath(caseData, fieldPath, questionDependsOnField));
  const label = translate(getLabelTranslationPath(caseData, fieldPath, labelDependsOnField));
  const validators:Array<ValidatorType> = [];

  useEffect(() => {
    if (required) {
      validators.push(requiredValidator());
    }
    if (numberType === "int") {
      validators.push(numberFieldDefault)
    }
    if (numberType === "float") {
      validators.push(floatFieldValidator)
    }
    if (getFormDefinitions(caseData, fieldPath) && getFormDefinitions(caseData, fieldPath).validators) {
      const definedValidators: Array<ValidatorType> = getFormDefinitions(caseData, fieldPath).validators;
      definedValidators.forEach(
        (definedValidator) => {
          validators.push(definedValidator);
        }
      );
    }
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>{question}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorTextField
          name={fieldPath}
          label={label}
          helperText={helperText}
          validators={validators}
          InputProps={{
            inputComponent: ThousandsNumberInput,
          }}
        />
      </Grid>
    </>
  );
}
