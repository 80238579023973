import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import contactSectionStyle from "./contactSectionStyle";
import HeadlineSection from "../HeadlineSection/HeadlineSection";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { MailOutlineRounded } from "@mui/icons-material";
import { Stack } from "@mui/material";
import Container from "../../../Container/Container";

const useStyles = makeStyles(contactSectionStyle);

export default function ContactSection({}) {
  const classes = useStyles();

  return (
    <Container>
      <Stack alignItems={"center"}>
        <HeadlineSection headline={"Kontakt per E-Mail"} typographyVariant={"h2"} />
        <Typography variant={"subtitle1"} className={classes.centerText}>
          Bei sonstigen Fragen erreichen Sie unser Serviceteam auch jederzeit unter folgender E-Mail-Adresse:
        </Typography>
        <div className={classes.buttonBackground} id={"email"}>
          <Button
            variant={"contained"}
            color={"primary"}
            component={"a"}
            href={"mailto:service@legalbird.de"}
            startIcon={<MailOutlineRounded />}
            className={classes.button}
          >
            E-Mail senden
          </Button>
        </div>
      </Stack>
    </Container>
  );
}
