import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./serviceWorldCaseItemStyle";
import Divider from "@mui/material/Divider";
import { Grid, ListItem } from "@mui/material";
import { Link } from "react-router-dom";
import { getCaseLink, getProductData } from "../../services/productService";
import Typography from "@mui/material/Typography";

const ServiceWorldCaseItem = ({ minimalCase, withDivider }) => {
  const classes = useStyles({});
  return (
    <>
      {withDivider && <Divider className={classes.divider} />}
      <ListItem button component={Link} to={getCaseLink(minimalCase)} className={classes.caseItem}>
        <Typography className={classes.reference}>{minimalCase.reference}</Typography>
        <Grid container alignItems={"center"} className={classes.innerContainer} wrap={"nowrap"}>
          <Grid item>
            <img
              src={getProductData(minimalCase.name, "productCategory.icon")}
              className={classes.icon}
              alt={"Produkt Icon"}
            />
          </Grid>
          <Grid item className={classes.textContainer}>
            <Typography variant={"h6"}>{getProductData(minimalCase.name, "productCategory.label")}</Typography>
            <div>{getProductData(minimalCase.name, "label")}</div>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

ServiceWorldCaseItem.propTypes = {
  minimalCase: PropTypes.object.isRequired,
  withDivider: PropTypes.bool,
};

ServiceWorldCaseItem.defaultProps = {
  withDivider: true,
};

export default ServiceWorldCaseItem;
