import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const MembershipSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"membershipType"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"reasonForDispute"} valuesDependOnField={"caseCategory"} labelDependsOnField={"caseCategory"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractNumber"} />

      {(contractLawCase.reasonForDispute === "cancellationContractExtension" ||
        contractLawCase.reasonForDispute === "cancellationRefused" ||
        contractLawCase.reasonForDispute === "serviceUnavailableCancellationCombined") && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"cancellationDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"endOfContractDate"} fieldType={"date"} />
        </>
      )}

      {(contractLawCase.reasonForDispute === "serviceUnavailable" || contractLawCase.reasonForDispute === "serviceUnavailableCancellationCombined") && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"serviceUnavailableOffer"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"serviceUnavailableFromDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"serviceUnavailableToDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberDaysAvailableInBetween"} />
        </>
      )}

      <CaseDetailsItem caseData={contractLawCase} fieldPath={"feePaymentFrequency"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"fee"} fieldType={"money"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"numberUnpaidPeriods"} />
    </Grid>
  );
};

MembershipSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default MembershipSpecificCaseDetails;
