import React from "react";
import PropTypes from "prop-types";
import ContentBox from "../../Box/ContentBox/ContentBox";
import { Box, Button, Link, Rating, Typography } from "@mui/material";
import iconReview from "../../../assets/icons/serviceOverview/icon_review_200px.png";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateResource } from "../../../services/reactQuery/reactQueryService";

export default function CustomerFeedbackProvenExpertBox({ customerFeedback, product }) {
  const updateMutation = useMutation(updateResource);
  const queryClient = useQueryClient();

  const handleClick = async () => {
    await updateMutation.mutateAsync({
      uri: "/customer_feedbacks",
      id: customerFeedback.id,
      data: { showProvenExpertRatingRequest: false },
    });
    await queryClient.invalidateQueries(["feedbackCollection", product.id]);
  };

  return (
    <ContentBox icon={iconReview} headline={"Danke für Ihre Bewertung!"}>
      <Box sx={{ textAlign: "center", marginBottom: 1 }}>
        <Rating name="stars" value={5} size="large" disabled />
      </Box>
      <Typography paragraph sx={{ color: "primary.main", fontWeight: 700, textAlign: "center" }}>
        Helfen Sie jetzt auch anderen Kunden, sich zu orientieren!
      </Typography>
      <Typography paragraph sx={{ textAlign: "center" }}>
        Über das Bewertungsportal Proven Expert können Sie die Unterstützung durch Legalbird öffentlich sichtbar darstellen und bewerten.
      </Typography>
      <Typography paragraph sx={{ textAlign: "center" }}>
        Dies dauert nur eine Minute und hilft anderen Rechtsuchenden bei der Auswahl der richtigen Kanzlei.
      </Typography>
      <Button
        fullWidth
        component={Link}
        href={"https://www.provenexpert.com/rwbj76/"}
        variant={"contained"}
        target={"_blank"}
        onClick={handleClick}
        sx={{ marginBottom: "1rem", textAlign: "center" }}
      >
        Auf Proven Expert bewerten
      </Button>
      <Typography paragraph sx={{ textAlign: "center" }}>
        Wir bedanken uns im Voraus und unterstützen Sie in Zukunft jederzeit gerne wieder!
      </Typography>
      <Button onClick={handleClick} fullWidth>
        Nicht bewerten
      </Button>
    </ContentBox>
  );
}
CustomerFeedbackProvenExpertBox.propTypes = {
  customerFeedback: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};
