import { useForm } from "../../provider/Form/FormProvider";
import ValidatorTextField from "../FormFields/ValidatorTextField";
import { emailDefault, isRequired, minStringLength, requiredValidator } from "../../services/validationRules";
import { InputAdornment, Link as MuiLink } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import ButtonLoading from "../Button/ButtonLoading";
import loginActionStyles from "./loginActionStyles";
import { Link } from "react-router-dom";

export default function LoginActionForm({ actionType, setActionType, isLoading }) {
  const [showPassword, setShowPassword] = useState(false);

  const buttonText = useMemo(() => {
    switch (actionType) {
      default:
      case "login":
        return "Zur Servicewelt";
      case "register":
        return "Jetzt starten";
      case "recover":
        return "Passwort zurücksetzen";
    }
  }, [actionType]);

  const prefixText = useMemo(() => {
    switch (actionType) {
      default:
        return "";
      case "register":
        return (
          <p style={loginActionStyles.text}>
            Neu bei Legalbird und noch keine E-Mail erhalten? Dann legen Sie sich jetzt ein neues Konto an.
            <br />
            <strong>
              Sie haben schon eine E-Mail erhalten? Dann haben Sie schon ein Konto und müssen dieses nur noch kurz
              bestätigen.
            </strong>
            <br />
            Nutzen Sie dazu einfach den Link in der E-Mail.
          </p>
        );
      case "recover":
        return (
          <p style={loginActionStyles.text}>
            Sie haben Ihr Passwort vergessen?
            <br />
            Gerne schicken wir Ihnen einen Link zum Zurücksetzen Ihres Passworts zu
          </p>
        );
    }
  }, [actionType]);

  const postfixText = useMemo(() => {
    switch (actionType) {
      default:
      case "login":
        return (
          <p style={loginActionStyles.text}>
            {"Noch kein Konto? "}
            <MuiLink onClick={() => setActionType("register")} sx={loginActionStyles.link} color="primary">
              Jetzt registrieren
            </MuiLink>
          </p>
        );
      case "register":
        return (
          <>
            <div style={loginActionStyles.termsOfServiceText}>
              Ich stimme den{" "}
              <Link to={"/agb"} sx={loginActionStyles.link} target="_blank">
                Legalbird AGB
              </Link>{" "}
              und dem{" "}
              <Link to={"/datenschutz"} sx={loginActionStyles.link} target="_blank">
                Datenschutz
              </Link>{" "}
              zu.
              <br />
            </div>
            <p style={loginActionStyles.text}>
              {"Bereits ein Konto? "}
              <MuiLink onClick={() => setActionType("login")} sx={loginActionStyles.link} color="primary">
                Jetzt anmelden
              </MuiLink>
            </p>
          </>
        );
      case "recover":
        return (
          <p style={loginActionStyles.text}>
            {"Zurück zur "}
            <MuiLink onClick={() => setActionType("login")} sx={loginActionStyles.link} color="primary">
              Anmeldung
            </MuiLink>
          </p>
        );
    }
  }, [actionType]);

  const { handleSubmit } = useForm();

  return (
    <form onSubmit={handleSubmit}>
      {prefixText}
      <ValidatorTextField
        label="Ihre E-Mail-Adresse"
        name="email"
        validators={[requiredValidator("Bitte geben Sie Ihre E-Mail-Adresse an"), emailDefault]}
      />
      {actionType === "login" && (
        <>
          <ValidatorTextField
            type={showPassword ? "text" : "password"}
            label="Passwort"
            name="password"
            validators={[requiredValidator("Bitte geben Sie Ihre Passwort an")]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div style={loginActionStyles.recoverPassword}>
            <MuiLink onClick={() => setActionType("recover")} sx={loginActionStyles.link} color="primary">
              Passwort vergessen?
            </MuiLink>
          </div>
        </>
      )}
      {actionType === "register" && (
        <>
          <ValidatorTextField
            name="givenName"
            label="Vorname"
            validators={[
              { validator: isRequired, message: "Bitte geben Sie den Vornamen an" },
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
          <ValidatorTextField
            name="familyName"
            label="Nachname"
            validators={[
              { validator: isRequired, message: "Bitte geben Sie den Nachnamen an" },
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </>
      )}
      <ButtonLoading
        sx={loginActionStyles.submitButton}
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
        isLoading={isLoading}
      >
        {buttonText}
      </ButtonLoading>
      {postfixText}
    </form>
  );
}
