import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./summaryStyle";
import Grid from "@mui/material/Grid";
import GridEditRow from "../../Common/GridEditRow/GridEditRow";
import { translate } from "../../../../services/translations/translations";
import Typography from "@mui/material/Typography";

const InsuranceData = ({ formData, product, formPath, title }) => {
  const classes = useStyles();
  const section = "insuranceData";

  return (
    <>
      <Grid item xs={12} className={classes.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.question}>
        <div id={"name"} className={classes.scrollToAnchor} />
        Versicherung
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "name",
          },
        }}
      >
        {product.insurance.insurance.name}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"insurancePolicyNumber"} className={classes.scrollToAnchor} />
        Versicherungsnummer
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "insurancePolicyNumber",
          },
        }}
      >
        {formData.insurance.insurancePolicyNumber}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"caseReferenceNumber"} className={classes.scrollToAnchor} />
        Schadennummer
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "caseReferenceNumber",
          },
        }}
      >
        {formData.insurance.caseReferenceNumber}
      </GridEditRow>
      <Grid item xs={12} className={classes.question}>
        <div id={"policyHolderType"} className={classes.scrollToAnchor} />
        Selbst oder mitversichert
      </Grid>
      <GridEditRow
        item
        xs={12}
        to={{
          pathname: formPath,
          state: {
            section: section,
            field: "policyHolderType",
          },
        }}
      >
        {translate(`${product.name}.insurance.policyHolderType.values.${formData.insurance.policyHolderType}`)}
      </GridEditRow>
      {formData.insurance.policyHolderType === "familyMember" && (
        <>
          <Grid item xs={12} className={classes.question}>
            <div id={"gender"} className={classes.scrollToAnchor} />
            Anrede Versicherter
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "gender",
              },
            }}
          >
            {formData.insurance.policyHolder.gender === "male" ? "Herr" : "Frau"}
          </GridEditRow>
          <Grid item xs={12} className={classes.question}>
            <div id={"givenName"} className={classes.scrollToAnchor} />
            Vorname Versicherter
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "givenName",
              },
            }}
          >
            {formData.insurance.policyHolder.givenName}
          </GridEditRow>
          <Grid item xs={12} className={classes.question}>
            <div id={"familyName"} className={classes.scrollToAnchor} />
            Nachname Versicherter
          </Grid>
          <GridEditRow
            item
            xs={12}
            to={{
              pathname: formPath,
              state: {
                section: section,
                field: "familyName",
              },
            }}
          >
            {formData.insurance.policyHolder.familyName}
          </GridEditRow>
        </>
      )}
    </>
  );
};

InsuranceData.propTypes = {
  formData: PropTypes.object.isRequired,
  formPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default InsuranceData;
