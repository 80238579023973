import React from "react";
import Grid from "@mui/material/Grid";
import _includes from "lodash/includes";
import MenuItem from "@mui/material/MenuItem";
import Iconize from "../../SvgIcon/Iconize";
import CheckSvg from "../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../assets/icons/common/active/kreuz.svg";
import MoneySvg from "../../../assets/icons/contract/inactive/icon_geld-einfordern.svg";
import MoneySvgActive from "../../../assets/icons/contract/active/icon_geld-einfordern_active.svg";
import GoodsSvg from "../../../assets/icons/contract/inactive/icon_ware-einfordern.svg";
import GoodsSvgActive from "../../../assets/icons/contract/active/icon_ware-einfordern_active.svg";
import QuestionmarkSvg from "../../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionmarkSvgActive from "../../../assets/icons/common/active/fragezeichen.svg";
import InputAdornment from "@mui/material/InputAdornment";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import {
  dateFieldDefault,
  isIban,
  isMoney,
  pastDateDefault,
  requiredValidator,
} from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import Group from "../../Wizard/Group";
import FormLegend from "../Common/FormLegend/FormLegend";
import { IbanInput } from "../../FormFields/MaskedInputs/IbanInput";
import ValidatorDateField from "../../FormFields/ValidatorDateField";

const ContractBaseEnforceClaim = ({ product }) => {
  const { values } = useForm();
  return (
    <>
      <Grid item xs={12}>
        <FormLegend>Welches Ziel verfolgen Sie?</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorSelectField
          label="Zielsetzung"
          name="objectiveClient"
          validators={[requiredValidator("Bitte geben Sie Ihr Ziel an")]}
        >
          <MenuItem value="fulfillment">Erfüllung des Vertrags</MenuItem>
          <MenuItem value="withdrawal">Rücktritt vom Vertrag</MenuItem>
          {!_includes(["travelContract", "membershipContract", "eventContract"], product.name) && (
            <MenuItem value="replacement">Ersatz oder Austausch</MenuItem>
          )}
          {!_includes(["travelContract", "membershipContract", "eventContract"], product.name) && (
            <MenuItem value="repair">Nachbesserung oder Reparatur</MenuItem>
          )}
          <MenuItem value="reduction">Volle oder teilweise Erstattung</MenuItem>
          <MenuItem value="compensation">Schadensersatz</MenuItem>
          <MenuItem value="other">Andere Zielsetzung</MenuItem>
        </ValidatorSelectField>
      </Grid>
      <Grid item xs={12}>
        <Group
          type="radio"
          name="subjectOfDispute"
          question="Möchten Sie Geld oder den Erhalt einer Ware/Leistung einfordern?"
          options={[
            {
              labelText: "Ich möchte Geld einfordern",
              labelIcon: <Iconize src={MoneySvg} alt={"Geld"} />,
              labelIconActive: <Iconize src={MoneySvgActive} alt={"Geld"} />,
              value: "money",
            },
            {
              labelText: "Ich möchte eine Ware oder Leistung einfordern",
              labelIcon: <Iconize src={GoodsSvg} alt={"Leistung"} />,
              labelIconActive: <Iconize src={GoodsSvgActive} alt={"Leistung"} />,
              value: "goods",
            },
            {
              labelText: "Ich fordere etwas ganz anderes",
              labelIcon: <Iconize src={QuestionmarkSvg} alt={"Sonstiges"} />,
              labelIconActive: <Iconize src={QuestionmarkSvgActive} alt={"Sonstiges"} />,
              value: "other",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.subjectOfDispute === "money" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wie viel Geld möchten Sie einfordern?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="Forderung in €"
              name="claimedAmountClient"
              validators={[
                requiredValidator("Bitte geben Sie Ihre Forderung an."),
                {
                  validator: isMoney,
                  message: "Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00",
                },
              ]}
              helperText="Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00"
            />
          </Grid>
          <Grid item xs={12}>
            <FormLegend>
              Falls eine Rückerstattung Ihrer Forderung erwirkt wird: Auf welche Bankverbindung soll das Geld überwiesen
              werden?
            </FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="IBAN für Rückerstattung"
              name="clientBankData.iban"
              validators={[
                requiredValidator("Bitte geben Sie Ihre IBAN an."),
                {
                  validator: isIban,
                  message: "Die eingegebene IBAN ist ungültig",
                },
              ]}
              InputProps={{
                startAdornment: <InputAdornment position="start">DE</InputAdornment>,
                inputComponent: IbanInput,
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Group
          type="radio"
          name="clientHasAlreadyClaimed"
          question="Haben Sie bereits schriftlich eine Forderung gestellt?"
          options={[
            {
              labelText: "Ja, ich habe bereits etwas eingefordert.",
              labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
              labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"} />,
              value: "yes",
            },
            {
              labelText: "Nein, ich habe bisher nichts eingefordert.",
              labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
              labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"} />,
              value: "no",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.clientHasAlreadyClaimed === "yes" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wann haben Sie schriftlich etwas eingefordert?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDateClient"
              label="Datum des Forderungsschreibens"
              validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault, pastDateDefault]}
            />
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="claimedDeadlineClient"
              label="Frist im Forderungsschreiben "
              validators={[dateFieldDefault]}
            />
          </Grid>
        </>
      )}
    </>
  );
};
export default ContractBaseEnforceClaim;
