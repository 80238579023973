import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  headline: {
    fontWeight: 500,
  },
  reference: {
    textAlign: "right",
    paddingTop: 5,
  },
  icon: {
    width: 30,
    height: 30,
  },
  textContainer: {
    marginLeft: 25,
    wordBreak: "break-word",
  },
  innerContainer: {
    marginTop: 5,
    fontSize: "1rem",
  },
  caseItem: {
    padding: "0 25px 29px 25px",
    display: "block",
  },
  divider: {
    backgroundColor: "#e6e6e6",
  },
}));

export default useStyles;
