import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsMembership = ({ caseData, formUrl }: SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"membershipType"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem
        fieldPath={"reasonForDispute"}
        formUrl={formUrl}
        caseData={caseData}
        valuesDependOnField={"caseCategory"}
        labelDependsOnField={"caseCategory"}
      />
      <SummaryDetailsItem fieldPath={"contractNumber"} formUrl={formUrl} caseData={caseData} />

      {(caseData.reasonForDispute === "cancellationRefused" || caseData.reasonForDispute === "cancellationContractExtension") && (
        <>
          <SummaryDetailsItem fieldPath={"cancellationDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} />
          <SummaryDetailsItem fieldPath={"endOfContractDate"} formUrl={formUrl} caseData={caseData} fieldType={"date"} />
        </>
      )}

      <SummaryDetailsItem fieldPath={"feePaymentFrequency"} formUrl={formUrl} caseData={caseData} />
      <SummaryDetailsItem fieldPath={"fee"} formUrl={formUrl} caseData={caseData} fieldType={"money"} />
      <SummaryDetailsItem fieldPath={"numberUnpaidPeriods"} formUrl={formUrl} caseData={caseData} />
    </>
  );
};

export default SummaryDetailsMembership;
