import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import { Grid, Hidden, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import classNames from "classnames";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Iconize from "../../../SvgIcon/Iconize";
import GymSvg from "../../../../assets/icons/contract/inactive/icon_gym.svg";
import GymSvgActive from "../../../../assets/icons/contract/active/icon_gym_active.svg";
import ClubSvg from "../../../../assets/icons/contract/inactive/icon_club.svg";
import ClubSvgActive from "../../../../assets/icons/contract/active/icon_club_active.svg";
import QuestionMarkSvg from "../../../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionMarkSvgActive from "../../../../assets/icons/common/active/fragezeichen.svg";
import IconCancellationSvgRefused from "../../../../assets/icons/contract/inactive/icon_wehren.svg";
import IconCancellationSvgRefusedActive from "../../../../assets/icons/contract/active/icon_wehren_active.svg";
import { translate } from "../../../../services/translations/translations";
import { dateFieldDefault, isNumber, moneyFieldDefault, pastOrSameDateDefault, requiredValidator } from "../../../../services/validationRules";
import TimeSvg from "../../../../assets/icons/shortTimeWork/inactive/time.svg";
import TimeSvgActive from "../../../../assets/icons/shortTimeWork/active/time.svg";
import { useForm } from "../../../../provider/Form/FormProvider";
import Group from "../../../Wizard/Group";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../FormFields/ValidatorDateField";

const useStyles = makeStyles(wizardStyle);
export default function MembershipContractDetails({ submitProps }) {
  const classes = useStyles();
  const { values, handleSubmit } = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="membershipType"
                question="Um welche Art von Mitgliedschaft handelt es sich?"
                options={[
                  {
                    labelText: translate(`${values.name}.membershipType.values.gym`),
                    labelIcon: <Iconize src={GymSvg} alt={translate(`${values.name}.membershipType.values.gym`)} />,
                    labelIconActive: <Iconize src={GymSvgActive} alt={translate(`${values.name}.membershipType.values.gym`)} />,
                    value: "gym",
                  },
                  {
                    labelText: translate(`${values.name}.membershipType.values.club`),
                    labelIcon: <Iconize src={ClubSvg} alt={translate(`${values.name}.membershipType.values.club`)} />,
                    labelIconActive: <Iconize src={ClubSvgActive} alt={translate(`${values.name}.membershipType.values.club`)} />,
                    value: "club",
                  },
                  {
                    labelText: translate(`${values.name}.membershipType.values.other`),
                    labelIcon: <Iconize src={QuestionMarkSvg} alt={translate(`${values.name}.membershipType.values.other`)} />,
                    labelIconActive: <Iconize src={QuestionMarkSvgActive} alt={translate(`${values.name}.membershipType.values.other`)} />,
                    value: "other",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>

            {values.caseCategory === "enforceClaim" && (
              <Grid item xs={12}>
                <Group
                  type="radio"
                  name="reasonForDispute"
                  question="Welches Problem haben Sie mit Ihrer Mitgliedschaft?"
                  options={[
                    {
                      labelText: translate(`${values.name}.reasonForDispute.values.enforceClaim.cancellationContractExtension`),
                      labelIcon: <Iconize src={TimeSvg} alt={translate(`${values.name}.reasonForDispute.values.enforceClaim.cancellationContractExtension`)} />,
                      labelIconActive: (
                        <Iconize src={TimeSvgActive} alt={translate(`${values.name}.reasonForDispute.values.enforceClaim.cancellationContractExtension`)} />
                      ),
                      value: "cancellationContractExtension",
                      optionHintText:
                        "Sie haben gekündigt und der Vertragspartner möchte Ihre Mitgliedschaft gegen Ihren Wunsch verlängern, " +
                        "z.B. weil Sie während des Corona Lockdowns nicht weiter bezahlt haben.",
                    },
                    {
                      labelText: translate(`${values.name}.reasonForDispute.values.enforceClaim.cancellationRefused`),
                      labelIcon: (
                        <Iconize src={IconCancellationSvgRefused} alt={translate(`${values.name}.reasonForDispute.values.enforceClaim.cancellationRefused`)} />
                      ),
                      labelIconActive: (
                        <Iconize
                          src={IconCancellationSvgRefusedActive}
                          alt={translate(`${values.name}.reasonForDispute.values.enforceClaim.cancellationRefused`)}
                        />
                      ),
                      value: "cancellationRefused",
                      optionHintText:
                        "Sie haben regulär oder außerordentlich gekündigt und der Vertragpartner hat Ihre Kündigung " +
                        "nicht akzeptiert. Außerordentliche Gründe sind z.B. dauerhafte gesundheitliche Einschränkungen mit ärztlichem Attest.",
                    },
                    {
                      labelText: translate(`${values.name}.reasonForDispute.values.enforceClaim.other`),
                      labelIcon: <Iconize src={QuestionMarkSvg} alt={translate(`${values.name}.reasonForDispute.values.enforceClaim.other`)} />,
                      labelIconActive: <Iconize src={QuestionMarkSvgActive} alt={translate(`${values.name}.reasonForDispute.values.enforceClaim.other`)} />,
                      value: "other",
                      optionHintText: "Sie können uns Ihr Problem in den folgenden Schritten noch genauer erklären.",
                    },
                  ]}
                  validators={[requiredValidator("Bitte geben Sie das Problem mit Mitgliedschaft an")]}
                />
              </Grid>
            )}

            {values.caseCategory === "defendClaim" && (
              <Grid item xs={12}>
                <Group
                  type="radio"
                  name="reasonForDispute"
                  question="Was ist der Grund für die Forderung des Vertragspartners?"
                  options={[
                    {
                      labelText: translate(`${values.name}.reasonForDispute.values.defendClaim.cancellationContractExtension`),
                      labelIcon: <Iconize src={TimeSvg} alt={translate(`${values.name}.reasonForDispute.values.defendClaim.cancellationContractExtension`)} />,
                      labelIconActive: (
                        <Iconize src={TimeSvgActive} alt={translate(`${values.name}.reasonForDispute.values.defendClaim.cancellationContractExtension`)} />
                      ),
                      value: "cancellationContractExtension",
                      optionHintText:
                        "Sie wurden vom Vertragspartner zur Zahlung aufgefordert, sind aber der Meinung, dass Ihr Vertrag wegen Ihrer Kündigung" +
                        "schon beendet ist und Sie deshalb nicht mehr zahlen müssen.",
                    },
                    {
                      labelText: translate(`${values.name}.reasonForDispute.values.defendClaim.other`),
                      labelIcon: <Iconize src={QuestionMarkSvg} alt={translate(`${values.name}.reasonForDispute.values.defendClaim.other`)} />,
                      labelIconActive: <Iconize src={QuestionMarkSvgActive} alt={translate(`${values.name}.reasonForDispute.values.defendClaim.other`)} />,
                      value: "other",
                      optionHintText: "Sie können uns Ihr Problem in den folgenden Schritten noch genauer erklären.",
                    },
                  ]}
                  validators={[requiredValidator("Bitte geben Sie das Problem mit Ihrer Mitgliedschaft an")]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ValidatorTextField
                name="contractNumber"
                label={translate(`${values.name}.contractNumber.label`)}
                helperText="Sie finden diese Nummer z.B. auf Ihren Rechnungen oder Ihrem Mitgliedschaftsvertrag"
              />
            </Grid>

            {(values.reasonForDispute === "cancellationRefused" ||
              values.reasonForDispute === "cancellationContractExtension" ||
              values.reasonForDispute === "serviceUnavailableCancellationCombined") && (
              <>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>Wann haben Sie Ihre Kündigung abgeschickt?</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorDateField
                    label={translate(`${values.name}.cancellationDate.label`)}
                    name={"cancellationDate"}
                    validators={[requiredValidator("Bitte geben Sie das Kündigungsdatum an"), dateFieldDefault, pastOrSameDateDefault]}
                  />
                </Grid>

                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>Wann läuft oder lief der Vertrag aus, d.h. zu welchem Datum haben Sie gekündigt?</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorDateField
                    label={translate(`${values.name}.endOfContractDate.label`)}
                    name="endOfContractDate"
                    helperText={"Das Datum des Vertragsendes entnehmen Sie bitte den Kündigungsbedingungen Ihres Vertrags"}
                    validators={[dateFieldDefault]}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>Wie bezahlen Sie die Beiträge für Ihre Mitgliedschaft?</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate(`${values.name}.feePaymentFrequency.label`)}
                name="feePaymentFrequency"
                validators={[requiredValidator("Bitte geben Sie die Zahlungsfrequenz an")]}
              >
                <MenuItem value="monthly">{translate(`${values.name}.feePaymentFrequency.values.monthly`)}</MenuItem>
                <MenuItem value="quarterly">{translate(`${values.name}.feePaymentFrequency.values.quarterly`)}</MenuItem>
                <MenuItem value="yearly">{translate(`${values.name}.feePaymentFrequency.values.yearly`)}</MenuItem>
              </ValidatorSelectField>
            </Grid>
            {values.feePaymentFrequency && (
              <>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>
                    {values.feePaymentFrequency === "monthly" && <>Welchen Betrag zahlen Sie monatlich?</>}
                    {values.feePaymentFrequency === "quarterly" && <>Welchen Betrag zahlen Sie pro Quartal?</>}
                    {values.feePaymentFrequency === "yearly" && <>Welchen Betrag zahlen Sie jährlich?</>}
                  </FormLegend>
                </Grid>

                <Grid item xs={12}>
                  <ValidatorTextField
                    label={translate(`${values.name}.fee.label`)}
                    name="fee"
                    validators={[requiredValidator("Bitte geben Sie den Betrag an."), moneyFieldDefault]}
                  />
                </Grid>

                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>
                    Haben Sie Beiträge NICHT gezahlt, und falls ja, wie viele
                    {values.feePaymentFrequency === "monthly" && " Monate haben Sie im relevanten Zeitraum nicht gezahlt?"}
                    {values.feePaymentFrequency === "quarterly" && " Quartale haben Sie im relevanten Zeitraum nicht gezahlt?"}
                    {values.feePaymentFrequency === "yearly" && " Jahre haben Sie im relevanten Zeitraum nicht gezahlt?"}
                  </FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    name="numberUnpaidPeriods"
                    label={translate(`${values.name}.numberUnpaidPeriods.label`)}
                    validators={[
                      requiredValidator(
                        "Bitte geben Sie ein, wenn Sie innerhalb des relevanten Zeitraums nicht gezahlt haben. Wenn Sie monatlich zahlen und zum Beispiel zwei Monate nicht gezahlt haben, geben Sie hier eine “2” an. Wenn Sie durchgehend gezahlt haben, tragen Sie “0” ein."
                      ),
                      { validator: isNumber, message: "Bitte geben Sie die Anzahl als ganze Zahl an, z.B. 2" },
                    ]}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}
