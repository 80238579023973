import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./serviceWorldContentBoxStyle";
import { Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

const ServiceWorldContentBox = ({ icon, altText, headline, children }) => {
  const classes = useStyles({});

  return (
    <Paper elevation={4} className={classes.contentBoxContainer}>
      <Grid container justifyContent={"center"} wrap={"nowrap"} className={classes.contentBoxHeadline}>
        <Grid item>
          <Grid container alignItems={"center"}>
            <Grid item className={classes.imageCircleContainer}>
              <img src={icon} alt={altText} className={classes.headerIcon} />
            </Grid>
            <Grid item>
              <Typography variant={"h5"} component={"h3"}>{headline}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
};

ServiceWorldContentBox.propTypes = {
  icon: PropTypes.string.isRequired,
  altText: PropTypes.string,
  headline: PropTypes.string.isRequired,
};

ServiceWorldContentBox.defaultProps = {
  altText: "Icon",
};

export default ServiceWorldContentBox;
