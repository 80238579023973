import { getProductData } from "./productService";
import { AbstractCase } from "../types/AbstractCase";
import { UseMutationResult } from "@tanstack/react-query";
import { UpdateResourceParams } from "./reactQuery/reactQueryService";

export const handleDocumentUploadDescriptionSave = async (
  product: AbstractCase,
  currentStep: number,
  description: string,
  updateMutation: UseMutationResult<any, unknown, UpdateResourceParams>
) => {
  let temporaryFilesToCombineClone = structuredClone(product.temporaryFilesToCombine);
  temporaryFilesToCombineClone[currentStep].description = description;

  await updateMutation.mutateAsync({
    uri: getProductData(product.name, "apiUri"),
    id: product.id,
    data: {
      temporaryFilesToCombine: temporaryFilesToCombineClone,
    },
  });
};

export const getNoteStep = (steps: number) => {
  return steps - 2;
};

export const getFinishedStep = (steps: number) => {
  return steps - 1;
};

export const getMainDocumentStep = () => {
  return 0;
};
