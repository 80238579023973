import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import { Grid, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import classNames from "classnames";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Iconize from "../../../SvgIcon/Iconize";
import CheckSvg from "../../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../../assets/icons/common/active/kreuz.svg";
import PhoneSvg from "../../../../assets/icons/contract/inactive/icon_phone.svg";
import PhoneSvgActive from "../../../../assets/icons/contract/active/icon_phone_active.svg";
import InternetSvg from "../../../../assets/icons/contract/inactive/icon_internet.svg";
import InternetSvgActive from "../../../../assets/icons/contract/active/icon_internet_active.svg";
import QuestionmarkSvg from "../../../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionmarkSvgActive from "../../../../assets/icons/common/active/fragezeichen.svg";
import { translate } from "../../../../services/translations/translations";
import { useForm } from "../../../../provider/Form/FormProvider";
import Group from "../../../Wizard/Group";
import { requiredValidator } from "../../../../services/validationRules";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";

const useStyles = makeStyles(wizardStyle);
export default function SubscriptionContractDetails({ submitProps, product }) {
  const classes = useStyles();
  const { handleSubmit } = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="wasDeceived"
                question="Wurden Sie aus Ihrer Sicht beim Abschluss des Abonnements getäuscht?"
                options={[
                  {
                    labelText: "Ja",
                    labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
                    labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"} />,
                    optionHintText:
                      "Ich wurde meiner Meinung nach getäuscht und war mir über den Abschluss des Abonnements nicht bewusst.",
                    value: "yes",
                  },
                  {
                    labelText: "Nein",
                    labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
                    labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"} />,
                    optionHintText:
                      "Ich wurde beim Abschluss nicht getäuscht, habe aber ein anderes Anliegen bezüglich eines Abonnements, bei dem ich rechtlichen Beistand benötige.",
                    value: "no",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="salesChannel"
                question="Bitte geben Sie hier an, wo Sie das Abonnement abgeschlossen haben"
                options={[
                  {
                    labelText: translate(`${product.name}.salesChannel.values.phone`),
                    labelIcon: <Iconize src={PhoneSvg} alt={translate(`${product.name}.salesChannel.values.phone`)} />,
                    labelIconActive: (
                      <Iconize src={PhoneSvgActive} alt={translate(`${product.name}.salesChannel.values.phone`)} />
                    ),
                    value: "phone",
                  },
                  {
                    labelText: translate(`${product.name}.salesChannel.values.online`),
                    labelIcon: (
                      <Iconize src={InternetSvg} alt={translate(`${product.name}.salesChannel.values.online`)} />
                    ),
                    labelIconActive: (
                      <Iconize src={InternetSvgActive} alt={translate(`${product.name}.salesChannel.values.online`)} />
                    ),
                    value: "online",
                  },
                  {
                    labelText: translate(`${product.name}.salesChannel.values.other`),
                    labelIcon: (
                      <Iconize src={QuestionmarkSvg} alt={translate(`${product.name}.salesChannel.values.other`)} />
                    ),
                    labelIconActive: (
                      <Iconize
                        src={QuestionmarkSvgActive}
                        alt={translate(`${product.name}.salesChannel.values.other`)}
                      />
                    ),
                    optionHintText: "Zum Beispiel vor Ort an einem Verkaufsstand oder auf schriftlichem Weg per Post.",
                    value: "other",
                  },
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>
                Wie lautet der Name oder die Beschreibung des Abonnements / der Serviceleistung?
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                label="Name des Abonnements"
                name="subscriptionName"
                validators={[requiredValidator("Bitte geben Sie den Namen an")]}
                helperText="Zum Beispiel: Zeitungsabo Rheinische Post"
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}
