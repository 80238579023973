import React, { useState } from "react";
import useStyles from "./uploadFineNoticeStyle";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import iconUpload from "../../../../assets/img/generic/icon_upload.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ContentBoxHead from "../../../../components/Box/ContentBox/ContentBoxHead";
import FileUploadSection from "../../../../components/Form/Common/FileUploadSection/FileUploadSection";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import ApiClient from "../../../../services/apiClient";
import Layout from "../../../Layouts/Layout";
import TextContainer from "../../../../components/Container/TextContainer";
import InputAdornment from "@mui/material/InputAdornment";
import infoIcon from "../../../../assets/icons/serviceOverview/icon_info.svg";
import DialogModal from "../../../../components/DialogModal/DialogModal";
import { dateFieldDefault, pastOrSameDateDefault, requiredValidator } from "../../../../services/validationRules";
import moment from "moment";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { FormProvider, useForm } from "../../../../provider/Form/FormProvider";
import ValidatorTextField from "../../../../components/FormFields/ValidatorTextField";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";

const TrafficFineNoticeForm = ({ traffic, handleInfoModalOpen, isLoading }) => {
  const classes = useStyles();
  const { handleSubmit, values } = useForm();
  return (
    <form onSubmit={handleSubmit}>
      <ValidatorTextField
        name="fineNoticeReceivedDate"
        label="Bescheid erhalten am"
        helperText={`z.B. ${moment().subtract(1, "weeks").format("DD.MM.YYYY")}`}
        validators={[requiredValidator("Bitte geben Sie an, wann Sie den Bußgeldbescheid erhalten haben."), dateFieldDefault, pastOrSameDateDefault]}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img src={infoIcon} alt={"(i)"} className={classes.info} onClick={handleInfoModalOpen} />
            </InputAdornment>
          ),
        }}
      />
      <ButtonLoading
        className={classes.button}
        type={"submit"}
        fullWidth
        variant={"contained"}
        color={"primary"}
        isLoading={isLoading}
        disabled={!values.fineNoticeReceivedDate || !(traffic.fineNoticeFiles && traffic.fineNoticeFiles.length > 0)}
      >
        Dokument hochladen
      </ButtonLoading>
    </form>
  );
};

export default function UploadFineNotice() {
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const classes = useStyles({});
  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = useProductParams();
  const productApiUri = getProductData("traffic", "apiUri");
  const queryClient = useQueryClient();
  const { data: traffic, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data),
  });

  if (isLoading) {
    return (
      <Layout product={"traffic"} active={"traffic-overview"}>
        <Container style={{ width: 250 }}>
          <LegalbirdLoader />
        </Container>
      </Layout>
    );
  }

  if (traffic.fineNoticeReceivedDate) {
    return <Navigate to={getCaseLink(traffic)} />;
  }

  const handleSubmit = async ({ values }) => {
    setIsLoadingInternal(true);
    ApiClient.post(`/generate_finenotice_mediaobject/traffic/${traffic.id}`, {
      body: JSON.stringify({
        fineNoticeReceivedDate: values.fineNoticeReceivedDate,
      }),
    }).then(
      () => queryClient.invalidateQueries([productApiUri, productId]),
      () => setIsLoadingInternal(false)
    );
  };

  const handleInfoModalOpen = () => {
    navigate(location.pathname, { state: { infoModalOpen: true } });
  };

  const handleModalClose = () => {
    navigate(-1);
  };

  return (
    <Layout product={"traffic"} active={"serviceworld"} displayBackgroundImage={true}>
      <TextContainer>
        <Paper elevation={4} className={classes.container}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <ContentBoxHead headline={"Bußgeldbescheid an Kundenservice schicken"} icon={iconUpload} altText={"Upload"} />
            </Grid>
            <Grid item className={classes.textBlock}>
              <p>
                Laden Sie hier Ihren Bußgeldbescheid als Bild oder PDF hoch, oder fotografieren Sie ihn einfach mit dem Handy ab. Bitte beachten Sie dabei, dass
                alles gut erkennbar und nichts abgeschnitten ist. Benutzen Sie bestenfalls beim Fotografieren den Blitz.
              </p>
            </Grid>
          </Grid>
          <FileUploadSection
            fileArrayName={"fineNoticeFiles"}
            productEntity={traffic}
            productEntityId={traffic.id}
            product={"traffic"}
            updateAction={async (id, data) =>
              await updateMutation.mutateAsync({
                uri: productApiUri,
                id: id,
                data: data,
              })
            }
            setIsBlocked={setIsBlocked}
            isBlocked={isBlocked}
            limitPdfUpload={true}
            fieldName={"fineNoticeFiles"}
            uploadFileLabel={"Datei auswählen"}
          />
          <div className={classes.question}>Wann haben Sie den Bußgeldbescheid erhalten?</div>
          <FormProvider initialValues={{ finenoticeReceivedDate: "" }} submitAction={handleSubmit}>
            <TrafficFineNoticeForm traffic={traffic} handleInfoModalOpen={handleInfoModalOpen} isLoading={isLoadingInternal} />
          </FormProvider>
        </Paper>
      </TextContainer>
      <DialogModal open={!!(location.state && location.state.infoModalOpen)} onClose={handleModalClose} title={"Datum der Zustellung"}>
        <p>
          Wichtig: Hier geht es darum, wann Ihnen der Brief (meist in gelbem Umschlag) von der Post zugestellt wurde. Auch wenn Sie zum Beispiel den Brief erst
          eine Woche nachdem er im Briefkasten lag geöffnet haben, gilt hier aus rechtlicher Sicht immer das Datum der Zustellung. Dieses Datum ist sehr
          wichtig, denn gegen einen Bußgeldbescheid kann nur innerhalb von zwei Wochen nach Zustellung bei Ihnen Einspruch eingelegt werden. Wird der Einspruch
          nicht innerhalb der Frist eingelegt, ist er unwirksam und wird von der Behörde als unzulässig verworfen.
        </p>
      </DialogModal>
    </Layout>
  );
}
