import React, { useState } from "react";
import { Button } from "@mui/material";
import loginIcon from "../../assets/img/generic/user_round.png";
import { AccountCircleOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import DialogModal from "../DialogModal/DialogModal";
import LoginAction from "../Login/LoginAction";
import loginIconStyle from "./loginIconStyle";
import { modalview, pageview } from "../../services/useTracker";

export default function LoginIcon({}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [loginLayerTitle, setLoginLayerTitle] = useState("Anmelden");

  const handleOpenLoginLayer = () => {
    setLoginLayerTitle("Anmelden");
    navigate(location.pathname, { state: { loginModalOpen: "loginIcon" } });
    modalview("Login Layer Modal");
  };

  const handleCloseLoginLayer = () => {
    navigate(-1);
    pageview(location.pathname);
  };

  return (
    <>
      <Button
        color="buttonText"
        endIcon={<AccountCircleOutlined />}
        onClick={handleOpenLoginLayer}
      >
        Anmelden
      </Button>
      <DialogModal
        open={!!(location.state && location.state.loginModalOpen === "loginIcon")}
        onClose={handleCloseLoginLayer}
        title={loginLayerTitle}
        maxWidth={"sm"}
        fullWidth
      >
        <div style={loginIconStyle.modalContainer}>
          <LoginAction loginLayerTitle={setLoginLayerTitle} />
        </div>
      </DialogModal>
    </>
  );
}

LoginIcon.propTypes = {};

LoginIcon.defaultProps = {};
