/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import { Grid, MenuItem } from "@mui/material";
import FormLegend from "../../Common/FormLegend/FormLegend";
import { getLabelTranslationPath, getQuestionTranslationPath, translate, translateHelperTextIfExists } from "../../../../services/translations/translations";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { requiredValidator } from "../../../../services/validationRules";
import { getFormDefinitions } from "../../../../services/formDefinitions/formDefinitions";

type SingleSelectFieldConfiguredProps = {
  caseData: any;
  fieldPath: string;
  required?: boolean | undefined;
  labelDependsOnField?: string | null;
  questionDependsOnField?: string | null;
};
export default function SingleSelectFieldConfigured(
  {
    caseData,
    fieldPath,
    required,
    labelDependsOnField = null,
    questionDependsOnField = null
  }: SingleSelectFieldConfiguredProps) {

  const helperText = translateHelperTextIfExists(caseData, fieldPath);
  const question = translate(getQuestionTranslationPath(caseData, fieldPath, questionDependsOnField));
  const label = translate(getLabelTranslationPath(caseData, fieldPath, labelDependsOnField));
  const validators = [];
  if (required) {
    validators.push(requiredValidator());
  }
  const menuItems = getFormDefinitions(caseData, fieldPath).options;

  return (
    <>
      <Grid item xs={12}>
        <FormLegend>{question}</FormLegend>
      </Grid>
      <Grid item xs={12}>
        <ValidatorSelectField label={label} name={fieldPath} validators={validators} helperText={helperText}>
          {menuItems.map((item: string, index: number) =>
            <MenuItem key={index} value={item}>{translate(`${caseData.name}.${fieldPath}.values.${item}`)}</MenuItem>
          )}
        </ValidatorSelectField>
      </Grid>
    </>
  );
}
