import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import footerStyle from "./footerStyle";
import { Grid, Stack } from "@mui/material";
import legalbirdLogo from "../../assets/img/logo/legalbird-logo-white.svg";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import footerTransition from "../../assets/img/background/Footer_overlay.svg";

const useStyles = makeStyles(footerStyle);

export default function Footer({}) {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <img className={classes.footerTransition} src={footerTransition} alt={"footertransition"} />
      <footer>
        <Grid container className={classes.footerContainer}>
          <Grid item xs={12} md={3} lg={2}>
            <img src={legalbirdLogo} alt={"Legalbird"} className={classes.logo} />
          </Grid>
          <Grid item xs={12} md={9} lg={8}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={3} lg={2}>
                <Typography variant={"h6"} className={classes.categoryHeadline}>
                  Legalbird
                </Typography>
                <Stack spacing={2}>
                  <Typography component={Link} color={"inherit"} to={"/ueber-uns"}>
                    Über uns
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/kontakt"}>
                    Kontakt
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/jobs"}>
                    Karriere
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/lp/partneranwalt-werden"}>
                    Partneranwalt werden
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <Typography variant={"h6"} className={classes.categoryHeadline}>
                  Rechtsbereiche
                </Typography>
                <Stack spacing={2}>
                  <Typography component={Link} color={"inherit"} to={"/familienrecht"}>
                    Familienrecht
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/arbeitsrecht"}>
                    Arbeitsrecht
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/verkehrsrecht"}>
                    Verkehrsrecht
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/vertragsrecht"}>
                    Vertragsrecht
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <Typography variant={"h6"} className={classes.categoryHeadline}>
                  Ratgeber
                </Typography>
                <Stack spacing={2}>
                  <Typography component={Link} color={"inherit"} to={"/ratgeber/familienrecht"}>
                    Familienrecht
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/ratgeber/arbeitsrecht"}>
                    Arbeitsrecht
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/ratgeber/verkehrsrecht"}>
                    Verkehrsrecht
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} md={3} lg={2}>
                <Typography variant={"h6"} className={classes.categoryHeadline}>
                  Rechtliches
                </Typography>
                <Stack spacing={2}>
                  <Typography component={Link} color={"inherit"} to={"/agb"}>
                    AGB
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/datenschutz"}>
                    Datenschutz
                  </Typography>
                  <Typography component={Link} color={"inherit"} to={"/impressum"}>
                    Impressum
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography color={"inherit"} className={classes.trademark}>
              © Legalbird 2022
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}
