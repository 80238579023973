import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CaseDetailsItem from "../../../Common/CaseDetailsItem/CaseDetailsItem";

const ProviderSpecificCaseDetails = ({ contractLawCase }: any) => {
  return (
    <Grid container>
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"providerType"} />
      <CaseDetailsItem caseData={contractLawCase} fieldPath={"reasonForDispute"} />

      {contractLawCase.reasonForDispute === "extraOptionGas" &&
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractSignedBy"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"extraOptionGasNotification"} fieldType={"date"} labelDependsOnField={"contractSignedBy"} />
          {contractLawCase.contractSignedBy === "client" &&
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractHasPriceFixing"} />
          }
        </>
      }

      {contractLawCase.reasonForDispute === "cancellationByProvider" && (
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"cancellationReceivedDate"} fieldType={"date"} />
      )}

      {contractLawCase.reasonForDispute === "priceIncrease" && contractLawCase.priceIncreaseAnnouncementDate && (
        <CaseDetailsItem caseData={contractLawCase} fieldPath={"priceIncreaseAnnouncementDate"} fieldType={"date"} />
      )}

      {contractLawCase.reasonForDispute === "cancellationRefused" && (
        <>
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"cancellationDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"endOfContractDate"} fieldType={"date"} />
          <CaseDetailsItem caseData={contractLawCase} fieldPath={"opponentReactionToCancellation"} />
          { contractLawCase.opponentReactionToCancellation === "differentDate" &&
            <CaseDetailsItem caseData={contractLawCase} fieldPath={"endOfContractDateOpponent"} fieldType={"date"} />
          }
        </>
      )}

      <CaseDetailsItem caseData={contractLawCase} fieldPath={"contractNumber"} />
    </Grid>
  );
};

ProviderSpecificCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

export default ProviderSpecificCaseDetails;
