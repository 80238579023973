import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import ApiClient from "../../../services/apiClient";
import { AbstractCase } from "../../../types/AbstractCase";
import { getProductData } from "../../../services/productService";
import ButtonLoading from "../../Button/ButtonLoading";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../services/reactQuery/reactQueryService";

type NoteFormStepProps = {
  product: AbstractCase;
  setCurrentStep: Function;
};

export default function NoteFormStep({ setCurrentStep, product }: NoteFormStepProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState("");
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    setIsLoading(true);
    await ApiClient.post(`/generate_combined_mediaobject/${product.name}/${product.id}`, {
      body: JSON.stringify({
        fileArrayName: "temporaryFilesToCombine",
        additionalMessage: note || null,
      }),
    });
    await queryClient.invalidateQueries([getProductData(product.name, "apiUri"), product.id]);
    await queryClient.invalidateQueries(
      queryKeys.collection("/media_objects", {
        product: product.name,
        productId: product.id,
      })
    );
    setIsLoading(false);
    setCurrentStep((currentStep: number) => currentStep + 1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={"h4"} sx={{ textAlign: "center" }}>
          Nachricht hinzufügen
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"subtitle2"} sx={{ textAlign: "center" }}>
          Sie möchten uns etwas zu den Dokumenten mitteilen? Dann schreiben Sie uns hier gerne eine kurze Nachricht.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField value={note} onChange={({ target }) => setNote(target.value)} multiline rows={10} label={"Nachricht hinzufügen (optional)"} fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button fullWidth onClick={() => setCurrentStep((currentStep: number) => currentStep - 1)}>
          Zurück
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <ButtonLoading fullWidth variant={"contained"} onClick={handleSubmit} isLoading={isLoading}>
          An Kundenservice schicken
        </ButtonLoading>
      </Grid>
    </Grid>
  );
}
