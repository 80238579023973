import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./divorceCostBoxStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Grid } from "@mui/material";
import DialogModal from "../../../DialogModal/DialogModal";
import BankDataContainer from "../BankDataContainer";
import FeesService from "../../../../services/FeesService";
import CostCalculator from "../../../../services/divorce/CostCalculator";
import classNames from "classnames";
import { modalview, pageview } from "../../../../services/useTracker";

const DivorceRealCostsBox = ({ divorce }) => {
  const classes = useStyles({});
  const location = useLocation();
  const navigate = useNavigate();

  if (!divorce) {
    return null;
  }

  const processCosts = divorce.costs.processAndExpensesReal
    ? divorce.costs.processAndExpensesReal
    : divorce.costs.processAndExpensesPre;
  const valueInDispute = CostCalculator.calculateValueInDispute(divorce);
  const installmentRate =
    (FeesService.getExpenses(true) +
      CostCalculator.getHearingCost(valueInDispute) +
      CostCalculator.getProcessCost(valueInDispute)) /
    10;
  const formattedInstallmentRate = (Math.round(installmentRate * 100) / 100).toLocaleString("de-DE");
  const hearingSub = divorce.costs.installment
    ? `Sie zahlen die Kosten für Ihren Anwalt in 10 Raten zu je ${formattedInstallmentRate} €.`
    : "Zu zahlen, sobald Ihr Scheidungstermin fest steht";

  const handleCloseBanklayer = () => {
    navigate(-1);
    pageview(location.pathname);
  };

  const handleOpenBanklayer = () => {
    navigate(location.pathname, { state: { bankModalOpen: "costBox" } });
    modalview("Bankdata Modal");
  };

  const costsSum =
    processCosts +
    (divorce.costs.hearingReal || divorce.costs.hearingPre) +
    (divorce.costs.courtReal || divorce.costs.courtPre);

  return (
    <Grid container justifyContent={"flex-end"}>
      <Grid item xs={8} className={classes.costRealLabel}>
        Verfahrensgebühr
      </Grid>
      <Grid item xs={4} className={classes.costRealValue}>
        {processCosts.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.paymentType === "private" ? (
        <Grid item xs={12} className={classes.costRealSub}>
          <span>Zu zahlen bei Einreichen des Scheidungsantrags</span>
        </Grid>
      ) : (
        [<br key={1} />, <br key={2} />]
      )}
      <Grid item xs={8} className={classes.costRealLabel}>
        Terminsgebühr
      </Grid>
      <Grid item xs={4} className={classes.costRealValue}>
        {divorce.costs.hearingReal
          ? divorce.costs.hearingReal.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
          : divorce.costs.hearingPre.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.paymentType !== "processCostSupport"
        ? [
            <Grid key={"1"} item xs={12} className={classes.costRealSub}>
              {hearingSub}
            </Grid>,
            <Grid key={"2"} item xs={12}>
              <Button variant="contained" fullWidth className={classes.paymentInfoButton} onClick={handleOpenBanklayer}>
                Bankdaten anzeigen
              </Button>
            </Grid>,
          ]
        : [<br key={1} />, <br key={2} />]}
      <Grid item xs={8} className={classes.costRealLabel}>
        Gerichtskosten
      </Grid>
      <Grid item xs={4} className={classes.costRealValue}>
        {divorce.costs.courtReal
          ? divorce.costs.courtReal.toLocaleString("de-DE", { style: "currency", currency: "EUR" })
          : divorce.costs.courtPre.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.paymentType !== "processCostSupport" && (
        <Grid item xs={12} className={classes.costRealSub}>
          Zu zahlen, sobald Sie die Rechnung vom Gericht erhalten haben
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={8} className={classNames(classes.costRealLabel, classes.sum)}>
        Scheidungskosten insgesamt
      </Grid>
      <Grid item xs={4} className={classNames(classes.costRealValue, classes.sum)}>
        {costsSum.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}
      </Grid>
      {divorce.processCostSupport && [
        <Grid key={"3"} item xs={12} className={classes.paymentHint}>
          Sie müssen voraussichtlich nichts zahlen!
        </Grid>,
        <Grid key={"4"} item xs={12} className={classes.paymentHintSub}>
          Wenn Ihr Antrag auf Verfahrenskostenhilfe bewilligt worden ist, sind alle Zahlungen über die
          Verfahrenskostenhilfe abgedeckt.
        </Grid>,
      ]}
      <DialogModal
        open={!!(location.state && location.state.bankModalOpen === "costBox")}
        onClose={handleCloseBanklayer}
        title={"Bankdaten"}
      >
        <BankDataContainer
          divorce={divorce}
          hearing={true}
          process={true}
          handleClose={handleCloseBanklayer}
          installmentRate={divorce.costs.installment ? formattedInstallmentRate : null}
        />
      </DialogModal>
    </Grid>
  );
};

DivorceRealCostsBox.propTypes = {
  divorce: PropTypes.object.isRequired,
};

export default DivorceRealCostsBox;
