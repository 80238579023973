import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import _filter from "lodash/filter";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import MultipleFileUpload from "../../Common/FileUpload/MultipleFileUpload";
import moment from "moment";
import FormSubmit from "../FormSubmit/FormSubmit";
import { useForm } from "../../../../provider/Form/FormProvider";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import FormLegend from "../FormLegend/FormLegend";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LegalbirdLoader from "../../../ContentLoader/LegalbirdLoader";
import { fetchCollection, queryKeys } from "../../../../services/reactQuery/reactQueryService";

const useStyles = makeStyles(wizardStyle);
export default function Documents({ product, submitProps, headline, mainDescription, subDescription }) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const urlParams = {
    product: product.name,
    productId: product.id,
  };
  const { data: mediaObjectCollection, isLoading } = useQuery(queryKeys.collection("/media_objects", urlParams), () =>
    fetchCollection("/media_objects", urlParams)
  );
  const { handleSubmit } = useForm();

  const refreshMediaObjectCollection = () => queryClient.invalidateQueries(["mediaObjectCollection", product.id]);

  if (isLoading) {
    return <LegalbirdLoader centered />;
  }

  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              {headline}
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12} className={classes.gridTopSpaced}>
              <FormLegend>{mainDescription}</FormLegend>
            </Grid>
            <Grid item xs={12} className={classes.gridTopSpaced}>
              <Typography variant={"h5"}>{headline}</Typography>
              <p>{subDescription}</p>
              <MultipleFileUpload
                product={product}
                fieldname="applicationDocument"
                description={"Dokument vom " + moment().format("DD.MM.YYYY")}
                mediaObjectCollection={_filter(mediaObjectCollection, ["fieldname", "applicationDocument"]) || []}
                fetchMediaObjects={refreshMediaObjectCollection}
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}
Documents.propTypes = {
  headline: PropTypes.string.isRequired,
  mainDescription: PropTypes.string.isRequired,
  subDescription: PropTypes.string.isRequired,
};
Documents.defaultProps = {
  headline: "Unterlagen",
  mainDescription:
    "Sie können hier gerne Unterlagen zu Ihrem Fall hochladen. Wenn Sie keine Unterlagen hochladen möchten, klicken Sie unten einfach auf “WEITER”.",
  subDescription: "Möchten Sie uns Unterlagen zukommen lassen, damit wir Ihr Anliegen besser einschätzen können? Dann laden Sie diese bitte hier hoch.",
};
