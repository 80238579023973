import React, {useEffect, useState} from "react";
import { isCookieFetched, mercureFetchCookie, mercureSubscribe } from "../../services/mercure";

const ReleaseInfo = () => {
  const [notification, setNotification] = useState({
    release: null
  });
  const cookieFetched = isCookieFetched();

  useEffect(() => {
    if (cookieFetched) {
      const eventSource = mercureSubscribe("/tech_status/release", setNotification);
      return () => {
        eventSource.close();
      };
    } else {
      mercureFetchCookie();
    }
  }, [cookieFetched]);

  useEffect(() => {
    if (notification && notification.release) {
      console.log("Release is running");
    }
    if (notification && !notification.release) {
      console.log("Release is finished");
    }
  }, [notification]);

};

export default ReleaseInfo;
