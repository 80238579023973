import React, { useEffect, useMemo, useState } from "react";
import { Box, Paper } from "@mui/material";
import ProgressBar from "../../ProgressBar/ProgressBar";
import pickDocumentIcon from "../../../assets/img/progress/icon_progressbar_vertrag-upload.png";
import furtherDocumentsIcon from "../../../assets/img/progress/icon_add_documents.png";
import addNoteIcon from "../../../assets/img/progress/icon_letter_150px.png";
import finishedIcon from "../../../assets/img/progress/icon_formsteps_finished.png";
import Container from "../../Container/Container";
import StepSwitch from "./StepSwitch";
import { AbstractCase } from "../../../types/AbstractCase";
import { useBeforeunload } from "react-beforeunload";
import LegalbirdLoader from "../../ContentLoader/LegalbirdLoader";
import { getFinishedStep, getMainDocumentStep, getNoteStep } from "../../../services/documentUploadFormService";

//progressbar step indices
const MAIN_DOCUMENT = 0;
const FURTHER_DOCUMENT = 1;
const NOTE = 2;
const FINISHED = 3;

const progressBarSteps = [
  {
    key: "mainDocument",
    name: "Dokument auswählen",
    icon: pickDocumentIcon,
    progress: 15,
  },
  {
    key: "furtherDocuments",
    name: "Weitere Dokumente",
    icon: furtherDocumentsIcon,
    progress: 40,
  },
  {
    key: "note",
    name: "Nachricht hinzufügen",
    icon: addNoteIcon,
    progress: 65,
  },
  {
    key: "finished",
    name: "An Kundenservice schicken",
    icon: finishedIcon,
    progress: 100,
  },
];

type DocumentUploadFormProps = {
  product: AbstractCase;
};

export default function DocumentUploadForm({ product }: DocumentUploadFormProps) {
  const documentCount = product.temporaryFilesToCombine.length;

  const [steps, setSteps] = useState(Math.max(documentCount + 3, 4));
  const [currentStep, setCurrentStep] = useState(Math.max(documentCount - 1, 0));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const progressBarStep = useMemo(() => {
    switch (currentStep) {
      case getMainDocumentStep():
        return progressBarSteps[MAIN_DOCUMENT];
      case getNoteStep(steps):
        return progressBarSteps[NOTE];
      case getFinishedStep(steps):
        return progressBarSteps[FINISHED];
      default:
        return progressBarSteps[FURTHER_DOCUMENT];
    }
  }, [currentStep]);

  useBeforeunload((e) => {
    if (currentStep !== getFinishedStep(steps)) {
      //for chrome returning a string is enough. preventDefault is to trigger it for other potential browsers
      e.preventDefault();
      return "";
    }
  });

  return (
    <Container>
      <ProgressBar steps={progressBarSteps} active={progressBarStep.key} progress={progressBarStep.progress} />
      <Paper sx={{ padding: "2rem" }}>
        <Box sx={{ maxWidth: 650, margin: "0 auto" }}>
          {isLoading ? (
            <LegalbirdLoader centered />
          ) : (
            <StepSwitch
              currentStep={currentStep}
              product={product}
              setCurrentStep={setCurrentStep}
              steps={steps}
              setSteps={setSteps}
              setIsLoading={setIsLoading}
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
}
