/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React, { StrictMode } from "react";
import App from "./App";
import _split from "lodash/split";
import { accessGranted } from "./services/environment";
import * as TagManager from "react-gtm-module";
import GtmConfig from "./services/gtmConfig";
import { detect } from "detect-browser";
import BrowserNotice from "./components/Browser/BrowserNotice";
import MaintenancePage from "./containers/Pages/Generic/MaintenancePage/MaintenancePage";
import { hasConsent } from "./services/cookieService";
import { createRoot } from "react-dom/client";

const MAINTENANCE_MODE = false;

const startApp = () => {
  const container = document.getElementById("root");
  // @ts-ignore
  let root = createRoot(container);

  const browser = detect();
  if (
    browser &&
    (browser.name === "ie" || (browser.name === "samsung" && parseInt(_split(browser.version, ".")[0]) < 6))
  ) {
    root.render(<BrowserNotice />);
    return;
  }

  if (!accessGranted(window.location.search)) {
    root.render(
      <div>
        <a href="https://www.legalbird.de">Weiter zu Legalbird</a>
      </div>
    );
    return;
  }

  if (MAINTENANCE_MODE) {
    root.render(<MaintenancePage />);
    return;
  }

  if (hasConsent("analytics")) {
    const tagManagerArgs = GtmConfig();
    // @ts-ignore
    TagManager.initialize(tagManagerArgs);
  }

  root.render(
      <StrictMode>
        <App />
      </StrictMode>
  );
};

startApp();
