import React from "react";
import * as PropTypes from "prop-types";
import useStyles from "./summaryStyle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SummaryDetailsItem from "../../Common/SummaryDetailsItem/SummaryDetailsItem";

const InsuranceData = ({ product, formPath, title }) => {
  const classes = useStyles({});
  const section = "insuranceData";

  if (!product.insurance.insurance) {
    return null;
  }
  return (
    <>
      <Grid item xs={12} className={classes.subHeadline}>
        <Typography variant={"h5"}>{title}</Typography>
      </Grid>
      <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.insurance.name"} anchorId={section} />
      <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.insurancePolicyNumber"} anchorId={section} />
      <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.caseReferenceNumber"} anchorId={section} />
      <SummaryDetailsItem caseData={product} formUrl={formPath} fieldPath={"insurance.policyHolderType"} anchorId={section} />

      {product.insurance.policyHolderType === "familyMember" && (
        <>
          <SummaryDetailsItem
            caseData={product}
            formUrl={formPath}
            fieldPath={"insurance.policyHolder.gender"}
            anchorId={section}
            labelPostfix={"Versicherter"}
          />
          <SummaryDetailsItem
            caseData={product}
            formUrl={formPath}
            fieldPath={"insurance.policyHolder.givenName"}
            anchorId={section}
            labelPostfix={"Versicherter"}
          />
          <SummaryDetailsItem
            caseData={product}
            formUrl={formPath}
            fieldPath={"insurance.policyHolder.familyName"}
            anchorId={section}
            labelPostfix={"Versicherter"}
          />
        </>
      )}
    </>
  );
};

InsuranceData.propTypes = {
  formPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default InsuranceData;
