/*
 * Copyright © 2022. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import SummaryDetailsItem from "../../../Common/SummaryDetailsItem/SummaryDetailsItem";
import SummaryDetailsProps from "../../../../../types/SummaryDetailsProps";

const SummaryDetailsVehicle = ({caseData: caseData, formUrl}:SummaryDetailsProps) => {
  return (
    <>
      <SummaryDetailsItem fieldPath={"buyerOrSeller"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"vehicleMake"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"model"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"vehicleId"} formUrl={formUrl} caseData={caseData}/>
      {caseData.objectiveClient === "withdrawal" && (
        <>
          <SummaryDetailsItem fieldPath={"mileageAtPurchase"} formUrl={formUrl} caseData={caseData}/>
          <SummaryDetailsItem fieldPath={"currentMileage"} formUrl={formUrl} caseData={caseData}/>
        </>
      )}

      <SummaryDetailsItem fieldPath={"purchasePrice"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"buyerPaidStatus"} formUrl={formUrl} caseData={caseData}/>
      <SummaryDetailsItem fieldPath={"locationOfGood"} formUrl={formUrl} caseData={caseData}
                          valuesDependOnField={"buyerOrSeller"}/>
      <SummaryDetailsItem fieldPath={"hasDefects"} formUrl={formUrl} caseData={caseData}/>

      {caseData.hasDefects === "yes" && (
        <>
          <SummaryDetailsItem fieldPath={"isAccidentCar"} formUrl={formUrl} caseData={caseData}/>
          <SummaryDetailsItem fieldPath={"defectsRecognitionDate"} formUrl={formUrl} caseData={caseData}
                              fieldType={"date"}/>
          <SummaryDetailsItem fieldPath={"defectsDescriptionClient"} formUrl={formUrl} caseData={caseData}/>
          <SummaryDetailsItem fieldPath={"sellerGuaranteedFreeFromDefects"} formUrl={formUrl} caseData={caseData}/>
          <SummaryDetailsItem fieldPath={"numberRepairAttempts"} formUrl={formUrl} caseData={caseData}/>
          <SummaryDetailsItem fieldPath={"numberReplacementAttempts"} formUrl={formUrl} caseData={caseData}/>
        </>
      )}
    </>
  );
};

export default SummaryDetailsVehicle;
