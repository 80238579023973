import React from "react";
import useStyles from "./serviceWorldStyle";
import EmailConfirmationBox from "./Common/EmailConfirmationBox";
import ServiceWorldMyCases from "./ServiceWorldMyCases";
import ServiceWorldNewCase from "./ServiceWorldNewCase";

const ServiceWorld = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <EmailConfirmationBox />
      <ServiceWorldMyCases />
      <ServiceWorldNewCase />
    </div>
  );
};

export default ServiceWorld;
