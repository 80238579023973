import { Helmet } from "react-helmet";
import Container from "../../../../components/Container/Container";
import Layout from "../../../Layouts/Layout";
import React, { useEffect, useState } from "react";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import FileUploadSection from "../../../../components/Form/Common/FileUploadSection/FileUploadSection";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import TextContainer from "../../../../components/Container/TextContainer";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import requestDocumentsPageStyle from "./requestDocumentsPageStyle";
import makeStyles from "@mui/styles/makeStyles";
import Paper from "@mui/material/Paper";
import ApiClient from "../../../../services/apiClient";
import { useNavigate } from "react-router-dom";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";

const useStyles = makeStyles(requestDocumentsPageStyle);

const RequestDocumentsPage = () => {
  const classes = useStyles();

  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);

  const { productId } = useProductParams();
  const productApiUri = getProductData("settlement", "apiUri");
  const { data: settlement, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource,{
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data)
  });

  const navigate = useNavigate();

  const updateSettlementAction = async (id, data) => {
    await updateMutation.mutateAsync({
      uri: "/settlements",
      id: id,
      data: data,
    });
  };

  const triggerUpdate = async () => {
    setIsLoadingInternal(true);
    await ApiClient.post(`/generate_requested_mediaobjects/settlement/${settlement.id}`);
    navigate(getCaseLink(settlement));
  };

  useEffect(() => {
    if (settlement) {
      let hasRequestedDocument = settlement.contract === null || settlement.contractTermination === null;
      if (!hasRequestedDocument) {
        navigate(getCaseLink(settlement));
      }
    }
  }, [settlement, navigate]);

  if (isLoading) {
    return (
      <Container style={{ width: 250 }}>
        <LegalbirdLoader />
      </Container>
    );
  }

  return (
    <Layout active="serviceworld" product={"settlement"}>
      <Helmet>
        <title>Legalbird - Fehlende Dokumente</title>
      </Helmet>
      <TextContainer>
        <HintBox headline={"Fehlende Dokumente ergänzen"}>Um Ihren Fall weiter bearbeiten zu können, benötigen wir noch folgende Unterlagen von Ihnen.</HintBox>
        <Paper elevation={4} className={classes.box}>
          {settlement.contract === null && (
            <FileUploadSection
              fileArrayName={"contractFiles"}
              headline={"Arbeitsvertrag"}
              helperText={
                "Bitte laden Sie Ihren vollständigen Arbeitsvertrag hoch. Sie können hier mehrere Seiten hochladen. Bitte achten Sie darauf, dass alle Seiten des Vertrages vorliegen."
              }
              productEntity={settlement}
              productEntityId={settlement.id}
              product={"settlement"}
              updateAction={updateSettlementAction}
              setIsBlocked={setIsBlocked}
              isBlocked={isBlocked}
              limitPdfUpload={true}
              fieldName={"contract"}
            />
          )}
          {settlement.contractTermination === null && (
            <FileUploadSection
              fileArrayName={"contractTerminationFiles"}
              headline={"Kündigung"}
              helperText={"Bitte laden Sie hier das Kündigungsschreiben hoch."}
              productEntity={settlement}
              productEntityId={settlement.id}
              product={"settlement"}
              updateAction={updateSettlementAction}
              setIsBlocked={setIsBlocked}
              isBlocked={isBlocked}
              limitPdfUpload={true}
              fieldName={"contractTermination"}
            />
          )}
          <br />
          <br />
          <ButtonLoading
            onClick={triggerUpdate}
            color="primary"
            variant="contained"
            disabled={false}
            fullWidth={true}
            type={"submit"}
            isLoading={isLoadingInternal}
          >
            Dokumente Hochladen
          </ButtonLoading>
        </Paper>
      </TextContainer>
    </Layout>
  );
};

export default RequestDocumentsPage;
