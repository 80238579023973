import React, { useEffect, useRef } from "react";
import useStyles from "./serviceWorldCaseStyle";
import { Helmet } from "react-helmet";
import StatusBox from "./StatusBox/StatusBox";
import ProcessBox from "./ProcessBox/ProcessBox";

import { getProductData, getProductNameBy } from "../../services/productService";
import { Link, useLocation } from "react-router-dom";
import LegalbirdLoader from "../ContentLoader/LegalbirdLoader";
import DocumentBox from "../PetitionSummary/DocumentBox";
import ServiceWorldCaseDetails from "./ServiceWorldCase/ServiceWorldCaseDetails";
import ServiceWorldCaseProductBoxes from "./ServiceWorldCase/ServiceWorldCaseProductBoxes";
import { Button, Grid } from "@mui/material";
import PageNavigation from "../Navigation/SummaryNavigation/PageNavigation";
import ServiceWorldCaseProductGuidebookBoxes from "./ServiceWorldCase/ServiceWorldCaseProductGuidebookBoxes";
import { gaSet } from "../../services/cookieService";
import CalendlyAppointment from "../Calendly/CalendlyAppointment";
import _get from "lodash/get";
import { getAppointmentDisplayType } from "../../services/serviceWorldService";
import CustomerFeedbackBox from "./Common/CustomerFeedbackBox";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../services/reactQuery/reactQueryService";
import ServiceWorldFileUploadLinkBox from "./ServiceWorldFileUploadLinkBox";
import useProductParams from "../../hooks/useProductParams";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

const ServiceWorldCase = () => {
  const classes = useStyles({});
  const { productName, productId } = useProductParams();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");
  const { data: product, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const { customer } = useCustomer();
  const location = useLocation();

  let refs = {
    "#upload": useRef(),
    "#documents": useRef(),
    "#details": useRef(),
    "#appointments": useRef(),
  };

  useEffect(() => {
    if (location.hash && refs[location.hash]) {
      refs[location.hash].current.scrollIntoView();
    }
  }, [product?.id]);

  const title = "Servicewelt Mein Fall | Legalbird";
  gaSet({ title: title });

  if (isLoading) {
    return (
      <Grid container justifyContent={"center"} className={classes.loaderContainer}>
        <Grid item>
          <LegalbirdLoader />
        </Grid>
      </Grid>
    );
  }

  let navigationLinks = [
    {
      target: "#status",
      label: "Jetzt vervollständigen",
    },
  ];

  if (product.isCustomerDataCompleted) {
    navigationLinks = [
      {
        target: "#status",
        label: "Status prüfen",
      },
      {
        target: "#documents",
        label: "Dokumente einsehen",
      },
      {
        target: "#upload",
        label: "Dokument hochladen",
      },
    ];
  }

  if (getAppointmentDisplayType(product) === "appointments") {
    navigationLinks.unshift({
      target: "#appointments",
      label: "Mein Beratungstermin",
    });
  }

  return (
    <div className={classes.contentContainer}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageNavigation
        navigationLinks={navigationLinks}
        headerIcon={getProductData(product.name, "productIcon")}
        headline={getProductData(product.name, "label")}
        subHeadline={product.reference}
      />
      <div className={classes.scrollToAnchor} id={"appointments"} ref={refs["#appointments"]} />
      <CalendlyAppointment calendlyData={_get(product, "advisoryAppointment")} customer={customer} />
      <CalendlyAppointment calendlyData={_get(product, "lawyerCounselingCall")} customer={customer} />
      <div className={classes.scrollToAnchor} id={"status"} ref={refs["#status"]} />
      <StatusBox product={product} />
      <CustomerFeedbackBox product={product} />
      <ProcessBox product={product} />
      <ServiceWorldCaseProductBoxes product={product} />
      <div className={classes.scrollToAnchor} id={"documents"} ref={refs["#documents"]} />
      <DocumentBox product={product} />
      <div className={classes.scrollToAnchor} id={"details"} ref={refs["#details"]} />
      <ServiceWorldCaseDetails product={product} />
      <div className={classes.scrollToAnchor} id={"upload"} ref={refs["#upload"]} />
      <ServiceWorldFileUploadLinkBox product={product} />
      <ServiceWorldCaseProductGuidebookBoxes product={product} />
      <Button fullWidth color="buttonGrey" component={Link} variant={"contained"} to={"/servicewelt"}>
        Zurück zur Servicewelt
      </Button>
    </div>
  );
};

export default ServiceWorldCase;
