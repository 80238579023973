import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import { Grid, Hidden, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import classNames from "classnames";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import { translate } from "../../../../services/translations/translations";
import { dateFieldDefault, requiredValidator } from "../../../../services/validationRules";
import { useForm } from "../../../../provider/Form/FormProvider";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../../FormFields/ValidatorDateField";

const useStyles = makeStyles(wizardStyle);
export default function EventContractDetails({ submitProps }) {
  const classes = useStyles();
  const { values, handleSubmit } = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>

            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>{translate(`${values.name}.reasonForDispute.question`)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate(`${values.name}.reasonForDispute.label`)}
                name="reasonForDispute"
                validators={[requiredValidator("Bitte geben Sie das Problem an.")]}
              >
                <MenuItem value="cancelled">{translate(`${values.name}.reasonForDispute.values.cancelled`)}</MenuItem>
                <MenuItem value="cancellationRefused">
                  {translate(`${values.name}.reasonForDispute.values.cancellationRefused`)}
                </MenuItem>
                <MenuItem value="other">{translate(`${values.name}.reasonForDispute.values.other`)}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>{translate(`${values.name}.eventType.question`)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorSelectField
                label={translate(`${values.name}.eventType.label`)}
                name="eventType"
                helperText={translate(`${values.name}.eventType.helperText`)}
              >
                <MenuItem value="private">{translate(`${values.name}.eventType.values.private`)}</MenuItem>
                <MenuItem value="public">{translate(`${values.name}.eventType.values.public`)}</MenuItem>
              </ValidatorSelectField>
            </Grid>

            {values.eventType === "public" && (
              <>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>{translate(`${values.name}.contractNumber.question`)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label={translate(`${values.name}.contractNumber.label`)}
                    name="contractNumber"
                    helperText={translate(`${values.name}.contractNumber.helperText`)}
                    validators={[requiredValidator("Bitte geben Sie die Buchungs- oder Ticketnummer an")]}
                  />
                </Grid>

                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>{translate(`${values.name}.numberOfTickets.question`)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorSelectField
                    label={translate(`${values.name}.numberOfTickets.label`)}
                    name="numberOfTickets"
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </ValidatorSelectField>
                </Grid>
              </>
            )}

            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>{translate(`${values.name}.eventTitle.question`)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name="eventTitle"
                label={translate(`${values.name}.eventTitle.label`)}
                helperText={translate(`${values.name}.eventTitle.helperText`)}
                validators={[requiredValidator("Bitte nennen Sie uns den Namen der Veranstaltung.")]}
              />
            </Grid>

            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>{translate(`${values.name}.eventCity.question`)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name="eventCity"
                label={translate(`${values.name}.eventCity.label`)}
                validators={[requiredValidator("Bitte nennen Sie uns die Stadt in der das Events stattfinden soll.")]}
              />
            </Grid>

            <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
              <FormLegend>{translate(`${values.name}.eventDate.question`)}</FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorDateField
                label={translate(`${values.name}.eventDate.label`)}
                name="eventDate"
                validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}
