import React from "react";
import * as PropTypes from "prop-types";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Link, useLocation } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { getProductData } from "../../services/productService";
import { useQueryClient } from "@tanstack/react-query";

const CalendlyBookingWidget = ({ responsibleParty, appointmentType, customer, product }) => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const productApiUri = getProductData(product.name, "apiUri");

  const queryClient = useQueryClient();

  useCalendlyEventListener({
    onEventScheduled: () => {
      queryClient.invalidateQueries([productApiUri, product.id]);
    },
  });

  return (
    <>
      <InlineWidget
        styles={{ height: "650px" }}
        url={`https://calendly.com/${responsibleParty}/${appointmentType}`}
        prefill={{ email: customer.email, name: customer.person.fullName }}
        utm={{ utmSource: urlSearchParams.get("utm_source") }}
      />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button color="buttonGrey" component={Link} to={"/servicewelt"} variant={"contained"}>
          Zurück zur Servicewelt
        </Button>
      </Box>
    </>
  );
};

CalendlyBookingWidget.propTypes = {
  responsibleParty: PropTypes.string.isRequired,
  appointmentType: PropTypes.string.isRequired,
  customer: PropTypes.object.isRequired,
};

export default CalendlyBookingWidget;
