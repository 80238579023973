import personTranslations from "./personTranslations";
import addressTranslations from "./addressTranslations";
import contactTranslations from "./contactTranslations";

const divorceTranslations = {
  label: "Scheidung",
  dateMarriage: {
    label: "Datum standesamtliche Heirat",
  },
  cityMarriage: { label: "Ort standesamtliche Heirat" },
  numberChildren: {
    question: "Haben Sie gemeinsame Kinder?",
    label: "Gemeinsame Kinder unter 18 Jahren",
    values: {
      "0": "keine Kinder",
      "1": "1 Kind",
      "2": "2 Kinder",
      "3": "3 Kinder",
      "4": "4 Kinder",
      "5": "5 Kinder",
      "6": "6 Kinder",
    },
  },
  statusDivorce: {
    label: "Einvernehmliche Scheidung?",
    question: "Gibt es Streitpunkte oder ist die Scheidung einvernehmlich?",
    values: {
      mutuallyAgreed: "Einvernehmliche Scheidung",
      debatable: "Offene Streitpunkte",
      unsure: "Ich bin mir nicht sicher",
    },
  },
  dateSeparation: { label: "Datum der Trennung" },
  movedOut: {
    label: "Aktuelle Wohnsituation",
    values: {
      petitioner: "Ich bin ausgezogen",
      partner: "Mein Partner ist ausgezogen",
      both: "Beide sind ausgezogen",
      nobody: "Keiner ist ausgezogen",
    },
  },
  dateMovedOut: { label: "Datum des Auszugs" },
  // Finance
  netIncomePetitioner: { label: "Ihr mtl. Nettoeinkommen in €" },
  netIncomePartner: { label: "Mtl. Nettoeinkommen Ihres Partners in €" },
  // Pensions
  pensionsExcluded: {
    label: "Versorgungsausgleich",
    question: "Möchten Sie den Versorgungsausgleich durchführen?",
    values: {
      no: "Ja, wir möchten den Versorgungsausgleich durchführen",
      yes: "Nein, wir möchten den Versorgungsausgleich notariell ausschließen lassen",
      false: "Ja, wir möchten den Versorgungsausgleich durchführen",
      true: "Nein, wir möchten den Versorgungsausgleich notariell ausschließen lassen",
    },
  },
  numberPensions: {
    label: "Ihre Rentenversicherungen",
    values: {
      "0": "keine",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
    },
  },
  numberPensionsPartner: {
    label: "Rentenversicherungen Partner",
    values: {
      "0": "keine",
      "1": "1",
      "2": "2",
      "3": "3",
      "4": "4",
      "5": "5",
    },
  },
  pensionsQuestions: {
    label: "Ich habe zum Thema Versorgungsausgleich noch Fragen und möchte diese im Nachgang besprechen",
    values: {
      yes: "Ja, ich habe noch Fragen zum Thema Versorgungsausgleich",
      no: "Nein, ich habe keine Fragen zum Thema Versorgungsausgleich",
      true: "Ja, ich habe noch Fragen zum Thema Versorgungsausgleich",
      false: "Nein, ich habe keine Fragen zum Thema Versorgungsausgleich",
    },
  },
  // ProcessCostSupport
  processCostSupport: {
    label: "Verfahrenskostenhilfe",
    question: "Möchten Sie für die Scheidungskosten Verfahrenskostenhilfe beantragen?",
    values: {
      yes: "Ja, ich möchte Verfahrenskostenhilfe beantragen",
      no: "Nein, ich möchte keine Verfahrenskostenhilfe beantragen",
      true: "Ja, ich möchte Verfahrenskostenhilfe beantragen",
      false: "Nein, ich möchte keine Verfahrenskostenhilfe beantragen",
    },
  },
  // Personal
  client: {
    ...personTranslations,
    nationality: { question: "Welche Staatsangehörigkeit haben Sie?", label: "Staatsangehörigkeit" },
  },
  partner: {
    ...personTranslations,
    nationality: { question: "Welche Staatsangehörigkeit hat Ihr Ehepartner?", label: "Staatsangehörigkeit" },
  },
  commonAddressType: {
    question: "Wie lautet die letzte gemeinsame Adresse von Ihnen und Ihrem Ehepartner?",
    values: {
      client: "Meine Adresse war unsere letzte gemeinsame Adresse",
      partner: "Die Adresse meines Ehepartners war unsere letzte gemeinsame Adresse",
      commonAddress: "Wir hatten zuvor eine andere gemeinsame Adresse",
    },
  },
  commonAddress: addressTranslations,
  customer: contactTranslations,
  personalTextMessage: { label: "Haben Sie offene Fragen?" },
};

export default divorceTranslations;
