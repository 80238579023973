import Grid from "@mui/material/Grid";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import { isPhoneNumberDefault, isRequired, minStringLength } from "../../../../services/validationRules";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import { MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import wizardStyle from "../../wizardStyle";
import PropTypes from "prop-types";
import FormLegend from "../FormLegend/FormLegend";
import { translate } from "../../../../services/translations/translations";
import { serviceTimes } from "../../../../services/formServiceFunctions";
import _map from "lodash/map";

const useStyles = makeStyles(wizardStyle);
export default function Contact({ path, headline, withContactTime }) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.gridTopSpaced}>
        <FormLegend>{headline}</FormLegend>
      </Grid>
      <Grid item xs={12} sm={withContactTime ? 6 : 12} className={withContactTime ? classes.gridSpace : null}>
        <ValidatorTextField
          name={`${path}.telephone`}
          label={translate("person.telephone.label")}
          autoComplete="tel"
          validators={[
            { validator: isRequired, message: "Bitte geben Sie Ihre Telefonnummer an" },
            { validator: minStringLength, params: [7], message: "Ihre Telefonnummer ist zu kurz" },
            isPhoneNumberDefault,
          ]}
        />
      </Grid>
      {withContactTime && (
        <Grid item xs={12} sm={6}>
          <ValidatorSelectField label={translate("person.contactTime.label")} name={`${path}.contactTime`}>
            {_map(serviceTimes(), (serviceTimeSlot, value) => (
              <MenuItem key={value} value={value}>
                {translate(`person.contactTime.values.${value}`)}
              </MenuItem>
            ))}
          </ValidatorSelectField>
        </Grid>
      )}
    </>
  );
}
Contact.propTypes = {
  path: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  withContactTime: PropTypes.bool.isRequired,
};
Contact.defaultProps = {
  path: "customer",
  headline: "Wie können wir Sie telefonisch am besten erreichen?",
  withContactTime: true,
};
