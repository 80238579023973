import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React from "react";
import { getCaseLink, getProductData } from "../productService";
import { getMaxApplicationStatus } from "../applicationStatus";
import * as leadStatusDocument from "../document/leadStatus";
import moment from "moment";
import _get from "lodash/get";

const getContent = (death) => {
  if (death.leadStatus < leadStatusDocument.STATUS_COMPLETED) {
    return {
      header: "Jetzt Ihr Dokument fertig stellen!",
      text: "Es fehlen nur noch wenige Angaben für Ihr Dokument. Im Dokument erhalten Sie zudem einen Link zum Vereinbaren eines Erstgesprächs.",
      buttonText: "Jetzt Fertigstellen",
    };
  }

  const appointmentStatus = _get(death, "advisoryAppointment.status");
  const appointmentDate =
    _get(death, "advisoryAppointment.appointment.date") &&
    moment(_get(death, "advisoryAppointment.appointment.date"));

  if (!appointmentDate) {
    return {
      header: "Ihr Dokument wurde erstellt",
      text: "Ihr persönlicher Ratgeber wurde erfolgreich erstellt. Sie finden den Ratgeber unter “Meine Dokumente”. Hier können Sie den Termin für eine Erstberatung vereinbaren:",
      buttonText: "Termin vereinbaren",
    };
  }

  if (appointmentStatus === "not_processed") {
    return {
      header: "Ihr Termin konnte nicht stattfinden",
      text:
        `Sie hatten einen Termin für ein Erstgespräch am ${
          appointmentDate && appointmentDate.format("DD.MM.YYYY")
        } gebucht. Der Termin konnte nicht stattfinden. Buchen Sie sich gerne einen neuen Termin.`,
      buttonText: "Neuen Termin buchen",
    };
  }

  if (appointmentDate.isAfter(moment())) {
    return {
      header: "Termin erfolgreich gebucht",
      text:
        "Sie haben einen Termin für ein Erstgespräch am " +
        appointmentDate.format("DD.MM.YYYY") +
        " gebucht. Ihr Rechtsanwalt wird Sie um " +
        appointmentDate.format("HH:mm") +
        " Uhr anrufen.",
    };
  }

  return {
    header: "Ihr Fall ist abgeschlossen",
    text: "Vielen Dank für Ihr Vertrauen und dafür, dass wir Sie unterstützen durften. Kommen Sie zukünftig gerne wieder auf uns zu!",
  };
};

const getButton = (death) => {
  const content = getContent(death);
  if (!content.buttonText) {
    return null;
  }

  if (death.leadStatus === leadStatusDocument.STATUS_COMPLETED) {
    const appointmentStatus = _get(death, "advisoryAppointment.status");
    const appointment =
      _get(death, "advisoryAppointment.appointment.date") &&
      moment(_get(death, "advisoryAppointment.appointment.date"));

    if (appointment && appointmentStatus !== "not_processed") {
      return null;
    }
    return (
      <Button
        fullWidth
        variant={"contained"}
        color={"primary"}
        component={Link}
        to={getCaseLink(death) + death.calendlyLink}
      >
        {content.buttonText}
      </Button>
    );
  }

  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      component={Link}
      to={getCaseLink(death) + "/antrag"}
    >
      {content.buttonText}
    </Button>
  );
};

const getPercentage = (death) => {
  if (death.leadStatus === leadStatusDocument.STATUS_CREATED) {
    const maxApplicationStatus = getMaxApplicationStatus(death.name);
    return (
      (Math.max(1, death.applicationStatus) / (maxApplicationStatus + 10)) * 50
    );
  }
  let appointment =
    _get(death, "advisoryAppointment.appointment.date") &&
    moment(_get(death, "advisoryAppointment.appointment.date"));

  if (!appointment) {
    return 75;
  }
  if (appointment.isAfter(moment())) {
    return 90;
  }
  return 100;
};

export const getStatusBoxConfigDeath = (death) => {
  let statusBoxConfig = {};
  const ctaButton = getButton(death);
  const content = getContent(death);

  statusBoxConfig.header = content.header;
  statusBoxConfig.text = content.text;
  statusBoxConfig.ctaButton = ctaButton;
  statusBoxConfig.productName = getProductData(death.name, "label");
  statusBoxConfig.percentage = getPercentage(death);

  return statusBoxConfig;
};
