import React from "react";
import * as PropTypes from "prop-types";
import { Grid } from "@mui/material";
import useStyles from "./contractLawDetailsStyles";
import moment from "moment";
import { translate } from "../../../../services/translations/translations";
import Typography from "@mui/material/Typography";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";

const GeneralContractLawCaseDetails = ({ contractLawCase }) => {
  const classes = useStyles({});
  const { customer } = useCustomer();
  const isAdvisory = contractLawCase.caseCategory === "advisory";

  return (
    <>
      <Typography variant={"h6"} className={classes.subheader}>
        Ihr Vertrag
      </Typography>
      <div className={classes.divider} />
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.label}>
              Art des Vertrags
            </Grid>
            <Grid item xs={12}>
              {translate(`${contractLawCase.name}.label`)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.label}>
              Ihr Anliegen
            </Grid>
            <Grid item xs={12}>
              {translate(`${contractLawCase.name}.caseCategory.values.${contractLawCase.caseCategory}`)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.label}>
              Ihr Name
            </Grid>
            <Grid item xs={12}>
              {translate(`${contractLawCase.name}.customer.person.gender.values.${customer.person.gender}`)} {customer.person.givenName}{" "}
              {customer.person.familyName}
            </Grid>
          </Grid>
        </Grid>
        {contractLawCase.clientIsCustomer && !isAdvisory && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={classes.label}>
                Ihre Adresse
              </Grid>
              <Grid item xs={12}>
                {customer.person.residenceAddress.streetAddress}
                <br />
                {customer.person.residenceAddress.postalCode} {customer.person.residenceAddress.addressLocality}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className={classes.label}>
              Ihre Telefonnummer
            </Grid>
            <Grid item xs={12}>
              {contractLawCase.customer.telephone}
            </Grid>
          </Grid>
        </Grid>
        {!contractLawCase.clientIsCustomer && !isAdvisory && (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} className={classes.label}>
                  Vertrag von
                </Grid>
                <Grid item xs={12}>
                  {translate(`${contractLawCase.name}.customer.person.gender.values.${contractLawCase.client.gender}`)} {contractLawCase.client.givenName}{" "}
                  {contractLawCase.client.familyName}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} className={classes.label}>
                  Adresse Vertragschließender
                </Grid>
                <Grid item xs={12}>
                  {contractLawCase.client.residenceAddress.streetAddress}
                  <br />
                  {contractLawCase.client.residenceAddress.postalCode} {contractLawCase.client.residenceAddress.addressLocality}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {contractLawCase.caseCategory !== "advisory" && (
          <>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} className={classes.label}>
                  Datum des Vertrags
                </Grid>
                <Grid item xs={12}>
                  {moment(contractLawCase.contractDate).format("DD.MM.YYYY")}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

GeneralContractLawCaseDetails.propTypes = {
  contractLawCase: PropTypes.object.isRequired,
};

GeneralContractLawCaseDetails.defaultProps = {};

export default GeneralContractLawCaseDetails;
