import React from "react";
import { Helmet } from "react-helmet";

import { gaSet } from "../../../../../services/cookieService";

const LpFamilyLawAdvoCardMeta = () => {
  const title = "Professionelle Hilfe im Familienrecht | Legalbird";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="Professionelle Hilfe im Familienrecht - Schnell, einfach und sicher! Jetzt beraten lassen!"
      />
      <meta name="keywords" content="Scheidung, Unterhalt, Erbe, Todesfall" />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="Professionelle Hilfe im Familienrecht - Schnell, einfach und sicher! Jetzt beraten lassen!"
      />
    </Helmet>
  );
};

export default LpFamilyLawAdvoCardMeta;
