import React from "react";
import { Checkbox, Grid, Stack, Typography } from "@mui/material";
import FormLegend from "../../Form/Common/FormLegend/FormLegend";
import ValidatorSelectField from "../../FormFields/ValidatorSelectField";
import MenuItem from "@mui/material/MenuItem";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import {
  emailDefault,
  isNotDuplicateMail,
  isPhoneNumberDefault,
  minStringLength,
  postalCodeDefault,
  requiredFieldDefault,
  streetAddressDefault,
  textFieldDefault,
} from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import { formValue } from "../../../services/formServiceFunctions";
import _isEmpty from "lodash/isEmpty";
import _includes from "lodash/includes";
import _map from "lodash/map";
import LoginIndependantFileUpload from "../../Form/Common/FileUpload/LoginIndependantFileUpload";
import ButtonLoading from "../../Button/ButtonLoading";
import ListItemText from "@mui/material/ListItemText";
import { translate } from "../../../services/translations/translations";

export default function ExternalLawyerApplicationForm({
  isLoading,
  lawyerCertificate,
  setLawyerCertificate,
  specialistLawyerCertificate,
  setSpecialistLawyerCertificate,
}) {
  const { values, handleSubmit, errors } = useForm();

  const submitDisabled =
    lawyerCertificate.length < 1 ||
    (formValue(values, "hasSpecialistTitles") === "true" && specialistLawyerCertificate.length < 1);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent={"center"} spacing={2}>
        <Grid item>
          <Typography variant={"h5"}>Jetzt bewerben</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Wie heißen Sie?</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField label="Anrede" name="gender" validators={[requiredFieldDefault]}>
            <MenuItem value={"female"}>Frau</MenuItem>
            <MenuItem value={"male"}>Herr</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorTextField
            name={`givenName`}
            label="Vorname"
            validators={[
              requiredFieldDefault,
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorTextField
            name={`familyName`}
            label="Nachname"
            validators={[
              requiredFieldDefault,
              { validator: minStringLength, params: [2], message: "Bitte kontrollieren Sie Ihre Eingabe" },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Wo wohnen Sie?</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField
            name={`streetAddress`}
            label="Straße und Hausnummer"
            validators={[requiredFieldDefault, streetAddressDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorTextField
            name={`postalCode`}
            label="Postleitzahl"
            validators={[requiredFieldDefault, postalCodeDefault]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ValidatorTextField name={`addressLocality`} label="Ort" validators={[requiredFieldDefault]} />
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Wie können wir Sie erreichen?</FormLegend>
        </Grid>
        <Grid item xs={12} md={6}>
          <ValidatorTextField
            name="email"
            label="Ihre E-Mail Adresse"
            validators={[
              requiredFieldDefault,
              emailDefault,
              {
                validator: isNotDuplicateMail,
                message:
                  "Sie haben schon ein Konto bei uns, oder haben sich bereits beworben? Dann nutzen Sie den Link aus der E-Mail, die Sie von uns erhalten haben, um sich anzumelden.",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ValidatorTextField
            name="telephone"
            label="Ihre Telefonnummer"
            autoComplete="tel"
            validators={[requiredFieldDefault, isPhoneNumberDefault]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Wie viele Jahre Berufserfahrung haben Sie?</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField
            label="Berufserfahrung (in Jahren)"
            name="yearsOfExperience"
            validators={[requiredFieldDefault]}
          >
            <MenuItem value={"<1"}>weniger als 1 Jahr</MenuItem>
            <MenuItem value={"1-5"}>1 - 5 Jahre</MenuItem>
            <MenuItem value={"5-10"}>5 - 10 Jahre</MenuItem>
            <MenuItem value={">10"}>mehr als 10 Jahre</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Haben Sie einen Fachanwaltstitel?</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField
            label="Fachanwaltstitel vorhanden"
            name="hasSpecialistTitles"
            validators={[requiredFieldDefault]}
          >
            <MenuItem value={"true"}>Ja</MenuItem>
            <MenuItem value={"false"}>Nein</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12}>
          <FormLegend>In welchen Rechtsbereichen können Sie uns unterstützen?</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField
            multiple
            label="Rechtsbereiche"
            name="legalFieldExperience"
            renderValue={(selected) =>
              _map(selected, (value) =>
                translate("externalLawyerApplication.legalFieldExperience.values." + value)
              ).join(", ")
            }
            validators={[requiredFieldDefault]}
            helperText={"Sie können mehrere Einträge in der Liste auswählen."}
          >
            <MenuItem value={"contractLaw"}>
              <Checkbox checked={_includes(values["legalFieldExperience"], "contractLaw")} />
              <ListItemText primary={"Vertragsrecht"} />
            </MenuItem>
            <MenuItem value={"familyLaw"}>
              <Checkbox checked={_includes(values["legalFieldExperience"], "familyLaw")} />
              <ListItemText primary={"Familienrecht"} />
            </MenuItem>
            <MenuItem value={"trafficLaw"}>
              <Checkbox checked={_includes(values["legalFieldExperience"], "trafficLaw")} />
              <ListItemText primary={"Verkehrsrecht"} />
            </MenuItem>
            <MenuItem value={"labourLaw"}>
              <Checkbox checked={_includes(values["legalFieldExperience"], "labourLaw")} />
              <ListItemText primary={"Arbeitsrecht"} />
            </MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Wie viele Stunden möchten Sie pro Woche für Legalbird arbeiten?</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField
            label="Arbeitsstunden pro Woche"
            name="weeklyHours"
            helperText={
              "Sie sollten im Schnitt mindestens 10 Stunden pro Woche Zeit investieren können, damit eine Partnerschaft für beide Seiten Sinn macht."
            }
            validators={[requiredFieldDefault]}
          >
            <MenuItem value={"10"}>10 Stunden</MenuItem>
            <MenuItem value={"15"}>15 Stunden</MenuItem>
            <MenuItem value={"20"}>20 Stunden</MenuItem>
            <MenuItem value={"25"}>25 Stunden</MenuItem>
            <MenuItem value={"30"}>30 Stunden</MenuItem>
            <MenuItem value={"35"}>35 Stunden</MenuItem>
            <MenuItem value={"40"}>40 Stunden</MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Welche der folgenden Aufgaben könnten Sie übernehmen?</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorSelectField
            label="Aufgabenart"
            name="taskType"
            multiple
            helperText={
              "Derzeit werden hauptsächlich Schriftsätze im Vertragsrecht gefertigt. Zukünftig werden weitere Aufgaben folgen. " +
              "Sie können mehrere Einträge in der Liste auswählen."
            }
            validators={[requiredFieldDefault]}
            renderValue={(selected) =>
              _map(selected, (value) => translate("externalLawyerApplication.taskType.values." + value)).join(", ")
            }
          >
            <MenuItem value={"legalDocuments"}>
              <Checkbox checked={_includes(values["taskType"], "legalDocuments")} />
              <ListItemText primary={"Schriftsätze fertigen (Außergerichtliche Schreiben, Klageschriften etc.)"} />
            </MenuItem>
            <MenuItem value={"checkCases"}>
              <Checkbox checked={_includes(values["taskType"], "checkCases")} />
              <ListItemText primary={"Ermittlungsakten prüfen (z.B. im Verkehrsrecht)"} />
            </MenuItem>
            <MenuItem value={"investigationFiles"}>
              <Checkbox checked={_includes(values["taskType"], "investigationFiles")} />
              <ListItemText primary={"Fallprüfung und Ersteinschätzung Vertragsrecht"} />
            </MenuItem>
          </ValidatorSelectField>
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Ihre Referenzen</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <ValidatorTextField
            name="referenceSites"
            label="Link für Referenz"
            validators={[textFieldDefault]}
            helperText={
              "Sie haben Online-Referenzen oder Bewertungen, wie ein Profil bei Anwalt.de, Google oder eine eigene Website, dann fügen Sie uns gerne den Link hier ein."
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormLegend>Ihre Unterlagen</FormLegend>
        </Grid>
        <Grid item xs={12}>
          <LoginIndependantFileUpload
            fieldname={"lawyerCertificate"}
            headline={"Anwaltszulassung"}
            helperText={"Bitte laden Sie hier Ihre Zulassungsurkunde oder Ihr zweites Staatsexamen hoch."}
            files={lawyerCertificate}
            setFiles={setLawyerCertificate}
          />
        </Grid>
        {formValue(values, "hasSpecialistTitles") === "true" && (
          <Grid item xs={12}>
            <LoginIndependantFileUpload
              fieldname={"specialistLawyerCertificate"}
              headline={"Nachweis Fachanwaltstitel"}
              helperText={
                "Sie haben angegeben, dass Sie einen Fachanwaltstitel besitzen. Bitte laden Sie hierzu einen Nachweis hoch."
              }
              files={specialistLawyerCertificate}
              setFiles={setSpecialistLawyerCertificate}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack alignItems={"center"} spacing={1}>
            {!_isEmpty(errors) && (
              <Typography variant={"body2"} sx={{ color: "red", textAlign: "center", maxWidth: "400px" }}>
                Leider sind Ihre Angaben noch nicht richtig oder vollständig. Bitte prüfen Sie Ihre Angaben oben.
              </Typography>
            )}
            <ButtonLoading
              variant={"contained"}
              isLoading={isLoading}
              type={"submit"}
              disabled={submitDisabled}
              sx={{ width: "200px" }}
            >
              Jetzt bewerben
            </ButtonLoading>
            <Typography variant={"body2"} sx={{ maxWidth: "400px" }}>
              Ich stimme dem{" "}
              <a href={"/datenschutz"} target={"_blank"}>
                Datenschutz
              </a>{" "}
              für die Verarbeitung meiner Bewerbung zu.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}