import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TextContainer from "../../Container/TextContainer";
import HintBox from "../../Box/HintBox/HintBox";
import EmailVerificationModal from "../../FormInfoModal/EmailVerificationModal";
import ButtonLoading from "../../Button/ButtonLoading";
import _isEmpty from "lodash/isEmpty";
import FileUploadSection from "../Common/FileUploadSection/FileUploadSection";
import { useForm } from "../../../provider/Form/FormProvider";
import Typography from "@mui/material/Typography";
import Iconize from "../../SvgIcon/Iconize";
import Group from "../../Wizard/Group";
import WritingSvg from "../../../assets/icons/traffic/inactive/icon_bescheid_inactive.svg";
import WritingSvgActive from "../../../assets/icons/traffic/active/icon_bescheid_active.svg";
import { dateFieldDefault, isTodayOrPastDate, oneOfTheseOptionsValidator, requiredValidator } from "../../../services/validationRules";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import infoIcon from "../../../assets/icons/serviceOverview/icon_info.svg";
import { useLocation, useNavigate } from "react-router-dom";
import DialogModal from "../../DialogModal/DialogModal";
import FormLegend from "../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../FormFields/ValidatorDateField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import { translate } from "../../../services/translations/translations";

export default function Documents({ product }) {
  const { handleSubmit, values } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data)
  });

  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (!localStorage.getItem("initialAuthorityNotification")) {
    localStorage.setItem("initialAuthorityNotification", values.authorityNotification);
  }
  const initialAuthorityNotification = localStorage.getItem("initialAuthorityNotification");

  const isSubmittable = !(
    isBlocked ||
    _isEmpty(product.files) ||
    values.authorityNotification === "no_notice" ||
    (values.authorityNotification === "fine_notice" && _isEmpty(values.fineNoticeReceivedDate))
  );

  const handleSubmitInternal = async (e) => {
    setIsLoading(true);
    await handleSubmit(e);
    localStorage.removeItem("initialAuthorityNotification");
  };

  const handleModalClose = () => {
    navigate(-1);
  };

  const handleInfoModalOpen = () => {
    navigate(location.pathname, { state: { infoModalOpen: true } });
  };

  const hasFineNotice = initialAuthorityNotification === "fine_notice";

  const updateAction = async (id, data) => {
    await updateMutation.mutateAsync({
      uri: "/traffic",
      id: id,
      data: {
        fineNoticeReceivedDate: values.fineNoticeReceivedDate,
        authorityNotification: values.authorityNotification,
        ...data,
      },
    });
  }

  return (
    <>
      <HintBox headline={"Ihre Angaben sind vollständig. Jetzt " + (hasFineNotice ? "Bußgeldbescheid" : "Dokument") + " hochladen!"}>
        {initialAuthorityNotification !== "no_notice" ? (
          <p>
            Damit wir Ihren Fall kostenlos prüfen können, benötigen wir jetzt noch
            {hasFineNotice
              ? " Ihren Bußgeldbescheid."
              : " das dazugehörige Dokument. Laden Sie hier bitte den Bußgeldbescheid, Anhörungsbogen, Zeugenfragebogen oder ähnliches hoch."}
            Wir melden uns dann zeitnah bei Ihnen, um das weitere Vorgehen zu besprechen.
          </p>
        ) : (
          <p>
            Da Sie bisher kein Dokument von der Behörde erhalten haben, melden wir uns nun zeitnah bei Ihnen, um das weitere Vorgehen zu besprechen. Sobald Sie
            Post von der Behörde bekommen haben, können Sie hier den Anhörungsbogen, Zeugenfragebogen oder Bußgeldbescheid hochladen und wir prüfen Ihren
            Bescheid kostenlos.
          </p>
        )}
      </HintBox>

      <Paper sx={{ padding: "2rem" }}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          {initialAuthorityNotification === "no_notice" && (
            <>
              <Typography variant={"h4"} sx={{ color: (theme) => theme.palette.primary.main }}>
                Wählen Sie bitte aus welche Art von Dokument Sie hochladen
              </Typography>
              <Group
                type="radio"
                name="authorityNotification"
                question=""
                options={[
                  {
                    labelText: translate("traffic.authorityNotification.values.fine_notice"),
                    labelIcon: <Iconize src={WritingSvg} alt={translate("traffic.authorityNotification.values.fine_notice")} />,
                    labelIconActive: <Iconize src={WritingSvgActive} alt={translate("traffic.authorityNotification.values.fine_notice")} />,
                    value: "fine_notice",
                  },
                  {
                    labelText: translate("traffic.authorityNotification.values.hearing_report"),
                    labelIcon: <Iconize src={WritingSvg} alt={translate("traffic.authorityNotification.values.hearing_report")} />,
                    labelIconActive: <Iconize src={WritingSvgActive} alt={translate("traffic.authorityNotification.values.hearing_report")} />,
                    value: "hearing_report",
                  },
                  {
                    labelText: translate("traffic.authorityNotification.values.witness_report"),
                    labelIcon: <Iconize src={WritingSvg} alt={translate("traffic.authorityNotification.values.witness_report")} />,
                    labelIconActive: <Iconize src={WritingSvgActive} alt={translate("traffic.authorityNotification.values.witness_report")} />,
                    value: "witness_report",
                  },
                ]}
                validators={[
                  oneOfTheseOptionsValidator(["fine_notice", "hearing_report", "witness_report"], "Bitte wählen Sie eine der angezeigten Antworten aus"),
                ]}
              />
              {values.authorityNotification === "fine_notice" && (
                <TextContainer>
                  <FormLegend>Wann haben Sie den Bußgeldbescheid erhalten?</FormLegend>
                  <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                    <ValidatorDateField
                      name="fineNoticeReceivedDate"
                      label="Bescheid erhalten am"
                      validators={[
                        requiredValidator("Bitte geben Sie an, wann Sie den Bußgeldbescheid erhalten haben."),
                        dateFieldDefault,
                        { validator: isTodayOrPastDate, message: "Das Datum darf nicht in der Zukunft liegen." },
                      ]}
                    />
                    <IconButton onClick={handleInfoModalOpen} sx={{ height: "40px", width: "40px" }}>
                      <img
                        src={infoIcon}
                        alt={"(i)"}
                        style={{
                          height: "1.25rem",
                          cursor: "help",
                        }}
                      />
                    </IconButton>
                  </Stack>
                </TextContainer>
              )}
            </>
          )}
          <Typography variant={"h4"} sx={{ color: (theme) => theme.palette.primary.main }}>
            Wichtig
          </Typography>
          <Box maxWidth={"700px"} margin={"0 auto"} textAlign={"center"}>
            Laden Sie hier {hasFineNotice ? "Ihren Bußgeldbescheid" : "Ihr Dokument"} als Bild oder PDF hoch, oder fotografieren Sie{" "}
            {hasFineNotice ? "ihn" : "es"} einfach mit dem Handy ab. Bitte beachten Sie dabei, dass alles gut erkennbar und nichts abgeschnitten ist. Benutzen
            Sie bestenfalls beim Fotografieren den Blitz.
            <Box textAlign={"right"}>
              <FileUploadSection
                fileArrayName={"files"}
                headline={""}
                helperText={""}
                productEntity={product}
                productEntityId={product.id}
                product={"traffic"}
                updateAction={updateAction}
                setIsBlocked={setIsBlocked}
                isBlocked={isBlocked}
                limitPdfUpload={true}
                fieldName={hasFineNotice ? "fineNotice" : "trafficGeneralFile"}
              />
            </Box>
          </Box>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sm={6} md={4}>
              <ButtonLoading fullWidth variant={"contained"} color={"primary"} disabled={!isSubmittable} isLoading={isLoading} onClick={handleSubmitInternal}>
                Jetzt kostenlos prüfen
              </ButtonLoading>
            </Grid>
          </Grid>
        </Stack>
      </Paper>
      <EmailVerificationModal />
      <DialogModal open={!!(location.state && location.state.infoModalOpen)} onClose={handleModalClose} title="Datum der Zustellung">
        <p>
          Wichtig: Hier geht es darum, wann Ihnen der Brief (meist in gelbem Umschlag) von der Post zugestellt wurde. Auch wenn Sie zum Beispiel den Brief erst
          eine Woche nachdem er im Briefkasten lag geöffnet haben, gilt hier aus rechtlicher Sicht immer das Datum der Zustellung. Dieses Datum ist sehr
          wichtig, denn gegen einen Bußgeldbescheid kann nur innerhalb von zwei Wochen nach Zustellung bei Ihnen Einspruch eingelegt werden. Wird der Einspruch
          nicht innerhalb der Frist eingelegt, ist er unwirksam und wird von der Behörde als unzulässig verworfen.
        </p>
      </DialogModal>
    </>
  );
}
