import React from "react";
import { useForm } from "../../../../provider/Form/FormProvider";
import DateFieldConfigured from "../../ConfiguredFormElements/DateFieldConfigured/DateFieldConfigured";
import { FormHeaderWrapper } from "../../Common/FormHeaderWrapper/FormHeaderWrapper";
import SingleSelectFieldConfigured from "../../ConfiguredFormElements/SingleSelectFieldConfigured/SingleSelectFieldConfigured";
import TextFieldConfigured from "../../ConfiguredFormElements/TextFieldConfigured/TextFieldConfigured";
import RadioGroupConfigured from "../../ConfiguredFormElements/RadioGroupConfigured/RadioGroupConfigured";
import { ContractDetailsProps } from "../../../../types/ContractDetailsProps";

export default function ProviderContractDetails({ product, submitProps }: ContractDetailsProps) {
  const { values, handleSubmit }: any = useForm();

  return (
    <FormHeaderWrapper submitProps={submitProps} handleSubmit={handleSubmit}>
      <SingleSelectFieldConfigured caseData={values} fieldPath={"providerType"} />
      <RadioGroupConfigured caseData={values} fieldPath={"reasonForDispute"} required />


      {values.reasonForDispute === "cancellationByProvider" && (
        <DateFieldConfigured caseData={product} fieldPath={"cancellationReceivedDate"} dateValidation={"pastOrToday"} />
      )}

      {values.reasonForDispute === "priceIncrease" && (
        <DateFieldConfigured caseData={product} fieldPath={"priceIncreaseAnnouncementDate"} dateValidation={"pastOrToday"} />
      )}

      {values.reasonForDispute === "cancellationRefused" && (
        <>
          <DateFieldConfigured caseData={product} fieldPath={"cancellationDate"} dateValidation={"pastOrToday"} required />
          <DateFieldConfigured caseData={product} fieldPath={"endOfContractDate"} />
          <RadioGroupConfigured caseData={values} fieldPath={"opponentReactionToCancellation"} required />
          { values.opponentReactionToCancellation === "differentDate" &&
            <DateFieldConfigured caseData={product} fieldPath={"endOfContractDateOpponent"} />
          }
        </>
      )}


      {values.contractSignedBy !== "landlord" &&
        <TextFieldConfigured caseData={product} fieldPath={"contractNumber"} required />
      }
    </FormHeaderWrapper>
  );
}
