import { apiPost, getToken } from "../../services/apiClient";
import ReactGA from "react-ga4";
import { isAdmin } from "../../services/localStorage";

export async function register(data) {
  const { token } = await apiPost("/customers", data);
  localStorage.setItem("token", token);
  ReactGA.event({
    category: "Registration",
    action: "Account created",
  });
}

export function login(credentials) {
  ReactGA.event({
    category: "Login",
    action: "logged in",
  });
  return getToken(credentials);
}

export async function loginImpersonate(data) {
  const adminToken = await getToken({ email: data.email.toLowerCase(), password: data.password });
  if (isAdmin(adminToken)) {
    localStorage.setItem("impersonate", data.login);
  }
}

export function logout() {
  ReactGA.event({
    category: "Customer",
    action: "Logged out",
    nonInteraction: true,
  });
  localStorage.removeItem("token");
  localStorage.removeItem("customer");
  localStorage.removeItem("impersonate");
}

export async function checkEmail(email) {
  try {
    let response = await apiPost("/check_email", { email: email.toLowerCase() });
    response.hasError = response.customerExists;
    response.errorText = null;
    return response;
  } catch (e) {
    return {
      hasError: true,
      errorText: "Ihre E-Mail-Adresse ist ungültig",
    };
  }
}
