import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../../wizardStyle";
import {Grid, Hidden} from "@mui/material";
import Paper from "@mui/material/Paper";
import FormFader from "../../Common/Fader/FormFader";
import classNames from "classnames";
import FormSubmit from "../../Common/FormSubmit/FormSubmit";
import Iconize from "../../../SvgIcon/Iconize";
import IncompleteSvg from "../../../../assets/icons/common/inactive/icon_incomplete.svg";
import IncompleteActiveSvg from "../../../../assets/icons/common/active/icon_incomplete_active.svg";
import BuyerSvg from "../../../../assets/icons/contract/inactive/icon_buyer.svg";
import BuyerSvgActive from "../../../../assets/icons/contract/active/icon_buyer_active.svg";
import SellerSvg from "../../../../assets/icons/contract/inactive/icon_seller.svg";
import SellerSvgActive from "../../../../assets/icons/contract/active/icon_seller_active.svg";
import LostPackageSvg from "../../../../assets/icons/contract/inactive/icon_lost_package.svg";
import LostPackageSvgActive from "../../../../assets/icons/contract/active/icon_lost_package_active.svg";
import CheckSvg from "../../../../assets/icons/common/inactive/haken.svg";
import CheckSvgActive from "../../../../assets/icons/common/active/haken.svg";
import CrossSvg from "../../../../assets/icons/common/inactive/kreuz.svg";
import CrossSvgActive from "../../../../assets/icons/common/active/kreuz.svg";
import QuestionMarkSvg from "../../../../assets/icons/common/inactive/fragezeichen.svg";
import QuestionMarkSvgActive from "../../../../assets/icons/common/active/fragezeichen.svg";
import {isMoney, requiredValidator} from "../../../../services/validationRules";
import MenuItem from "@mui/material/MenuItem";
import {translate} from "../../../../services/translations/translations";
import {useForm} from "../../../../provider/Form/FormProvider";
import ValidatorSelectField from "../../../FormFields/ValidatorSelectField";
import ValidatorTextField from "../../../FormFields/ValidatorTextField";
import Group from "../../../Wizard/Group";
import Typography from "@mui/material/Typography";
import FormLegend from "../../Common/FormLegend/FormLegend";

const useStyles = makeStyles(wizardStyle);
export default function PurchaseContractDetails({product, submitProps}) {
  const classes = useStyles();
  const {values, handleSubmit} = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Details zu Ihrem Vertrag</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <Group
                type="radio"
                name="buyerOrSeller"
                question={translate(`${product.name}.buyerOrSeller.question`)}
                options={[
                  {
                    labelText: translate(`${product.name}.buyerOrSeller.values.buyer`),
                    labelIcon: <Iconize src={BuyerSvg} alt={translate(`${product.name}.buyerOrSeller.values.buyer`)}/>,
                    labelIconActive: <Iconize src={BuyerSvgActive}
                                              alt={translate(`${product.name}.buyerOrSeller.values.buyer`)}/>,
                    value: "buyer"
                  },
                  {
                    labelText: translate(`${product.name}.buyerOrSeller.values.seller`),
                    labelIcon: <Iconize src={SellerSvg}
                                        alt={translate(`${product.name}.buyerOrSeller.values.seller`)}/>,
                    labelIconActive: <Iconize src={SellerSvgActive}
                                              alt={translate(`${product.name}.buyerOrSeller.values.seller`)}/>,
                    value: "seller"
                  }
                ]}
                validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
              />
            </Grid>
            {values.buyerOrSeller && (
              <>
                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>{translate(`${product.name}.channel.question.${values.buyerOrSeller}`)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorSelectField
                    label={translate(`${product.name}.channel.label.${values.buyerOrSeller}`)}
                    name="channel"
                    validators={[requiredValidator("Bitte geben Sie an wo Ihr Artikel gekauft wurde")]}
                  >
                    <MenuItem value="amazon">{translate(`${product.name}.channel.values.amazon`)}</MenuItem>
                    <MenuItem value="ebay">{translate(`${product.name}.channel.values.ebay`)}</MenuItem>
                    <MenuItem value="ebayClassifieds">
                      {translate(`${product.name}.channel.values.ebayClassifieds`)}
                    </MenuItem>
                    <MenuItem value="onlineOther">{translate(`${product.name}.channel.values.onlineOther`)}</MenuItem>
                    <MenuItem value="offline">{translate(`${product.name}.channel.values.offline`)}</MenuItem>
                    <MenuItem value="fleaMarket">{translate(`${product.name}.channel.values.fleaMarket`)}</MenuItem>
                    <MenuItem
                      value="kleiderkreisel">{translate(`${product.name}.channel.values.kleiderkreisel`)}</MenuItem>
                    <MenuItem value="other">{translate(`${product.name}.channel.values.other`)}</MenuItem>
                  </ValidatorSelectField>
                </Grid>

                {values.buyerOrSeller === "buyer" &&
                  values.channel !== "offline" &&
                  values.channel !== "fleaMarket" && (
                    <>
                      <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                        <FormLegend>{values.channel === "ebayClassifieds" ?
                          translate(`${product.name}.contractNumber.question.ebayClassifieds`)
                          : translate(`${product.name}.contractNumber.question.default`)}</FormLegend>
                      </Grid>
                      <Grid item xs={12}>
                        <ValidatorTextField
                          name="contractNumber"
                          label={translate(`${product.name}.contractNumber.label.${values.channel}`, "Bestellnummer")}
                          helperText="Sie finden diese Nummer z.B. auf Ihren Rechnungen oder in der Kaufbestätigungs-E-Mail."
                          validators={[requiredValidator("Bitte nennen Sie uns Ihre Bestellnummer oder Anzeigennummer.")]}
                        />
                      </Grid>
                    </>
                  )}

                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>
                    {translate(`${product.name}.itemTitle.question.${values.buyerOrSeller}`)}
                  </FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label={translate(`${product.name}.itemTitle.label`)}
                    name="itemTitle"
                    validators={[requiredValidator("Bitte geben Sie hier den Namen der Ware an.")]}
                  />
                </Grid>

                {values.buyerOrSeller === "buyer" && (
                  <>
                    <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                      <FormLegend>{translate(`${product.name}.articleNumber.question`)}</FormLegend>
                    </Grid>
                    <Grid item xs={12}>
                      <ValidatorTextField label={"Artikelnummer"} name={"articleNumber"}/>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>{translate(`${product.name}.purchasePrice.question`)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorTextField
                    label={translate(`${product.name}.purchasePrice.label`)}
                    name="purchasePrice"
                    validators={[
                      requiredValidator("Bitte geben Sie den Kaufpreis an."),
                      {
                        validator: isMoney,
                        message: "Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00"
                      }
                    ]}
                    helperText="Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00"
                  />
                </Grid>

                <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                  <FormLegend>{translate(`${product.name}.buyerPaidStatus.question`)}</FormLegend>
                </Grid>
                <Grid item xs={12}>
                  <ValidatorSelectField
                    label={translate(`${product.name}.buyerPaidStatus.label`)}
                    name="buyerPaidStatus"
                    validators={[requiredValidator("Bitte geben Sie den Zahlstatus an")]}
                  >
                    <MenuItem value="fully">{translate(`${product.name}.buyerPaidStatus.values.fully`)}</MenuItem>
                    <MenuItem
                      value="partially">{translate(`${product.name}.buyerPaidStatus.values.partially`)}</MenuItem>
                    <MenuItem value="none">{translate(`${product.name}.buyerPaidStatus.values.none`)}</MenuItem>
                  </ValidatorSelectField>
                </Grid>

                <Grid item xs={12}>
                  <Group
                    type="radio"
                    name="goodsOrServiceProvidedStatus"
                    question={translate(`${product.name}.goodsOrServiceProvidedStatus.question.${values.buyerOrSeller}`)}
                    options={[
                      {
                        labelText: translate(`${product.name}.goodsOrServiceProvidedStatus.values.${values.buyerOrSeller}.fully`),
                        labelIcon: <Iconize src={CheckSvg} alt={"Ja"}/>,
                        labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"}/>,
                        value: "fully"
                      },
                      {
                        labelText: translate(`${product.name}.goodsOrServiceProvidedStatus.values.${values.buyerOrSeller}.partially`),
                        labelIcon: <Iconize src={IncompleteSvg} alt={"Teilweise"}/>,
                        labelIconActive: <Iconize src={IncompleteActiveSvg} alt={"Teilweise"}/>,
                        value: "partially"
                      },
                      {
                        labelText: translate(`${product.name}.goodsOrServiceProvidedStatus.values.${values.buyerOrSeller}.none`),
                        labelIcon: <Iconize src={CrossSvg} alt={"Nein"}/>,
                        labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"}/>,
                        value: "none"
                      }
                    ]}
                    validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                  />
                </Grid>
                {values.goodsOrServiceProvidedStatus !== "none" && (
                  <>
                    <Grid item xs={12}>
                      <Group
                        type="radio"
                        name="locationOfGood"
                        question={translate(`${product.name}.locationOfGood.question`)}
                        options={[
                          {
                            labelText: translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.buyer`),
                            labelIcon: <Iconize src={BuyerSvg} alt={"Käufer"}/>,
                            labelIconActive: <Iconize src={BuyerSvgActive} alt={"Käufer"}/>,
                            value: "buyer"
                          },
                          {
                            labelText: translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.seller`),
                            labelIcon: <Iconize src={SellerSvg} alt={"Verkäufer"}/>,
                            labelIconActive: <Iconize src={SellerSvgActive} alt={"Verkäufer"}/>,
                            value: "seller"
                          },
                          {
                            labelText: translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.elsewhere`),
                            labelIcon: <Iconize src={LostPackageSvg}
                                                alt={translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.elsewhere`)}/>,
                            labelIconActive: <Iconize src={LostPackageSvgActive}
                                                      alt={translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.elsewhere`)}/>,
                            value: "elsewhere"
                          },
                          {
                            labelText: translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.unknown`),
                            labelIcon: <Iconize src={QuestionMarkSvg}
                                                alt={translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.unknown`)}/>,
                            labelIconActive: <Iconize src={QuestionMarkSvgActive}
                                                      alt={translate(`${product.name}.locationOfGood.values.${values.buyerOrSeller}.unknown`)}/>,
                            value: "unknown"
                          }
                        ]}
                        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                      />
                    </Grid>


                    <Grid item xs={12}>
                      <Group
                        type="radio"
                        name="hasDefects"
                        question={translate(`${product.name}.hasDefects.question.${values.buyerOrSeller}`)}
                        options={[
                          {
                            labelText: translate(`${product.name}.hasDefects.values.yes`),
                            labelIcon: <Iconize src={CheckSvg}
                                                alt={translate(`${product.name}.hasDefects.values.yes`)}/>,
                            labelIconActive: <Iconize src={CheckSvgActive} alt={"Ja"}/>,
                            value: "yes"
                          },
                          {
                            labelText: translate(`${product.name}.hasDefects.values.no`),
                            labelIcon: <Iconize src={CrossSvg}
                                                alt={translate(`${product.name}.hasDefects.values.no`)}/>,
                            labelIconActive: <Iconize src={CrossSvgActive} alt={"Nein"}/>,
                            value: "no"
                          }
                        ]}
                        validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
                      />
                    </Grid>

                    {values.hasDefects === "yes" && (
                      <>
                        <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                          <FormLegend>
                            {translate(`${product.name}.defectsDescriptionClient.question.${values.buyerOrSeller}`)}
                          </FormLegend>
                        </Grid>
                        <Grid item xs={12}>
                          <ValidatorTextField
                            label={translate(`${product.name}.defectsDescriptionClient.label`)}
                            name="defectsDescriptionClient"
                            validators={[requiredValidator("Bitte beschreiben Sie die Mängel.")]}
                            multiline
                            rows={6}
                            helperText={"Beschreiben Sie möglichst detailliert, damit wir eine gute Grundlage für das rechtliche Vorgehen haben und führen Sie mehrere Mängel in einer Liste mit Spiegelstrichen auf."}
                          />
                        </Grid>
                        <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                          <FormLegend>
                            {translate(`${product.name}.numberRepairAttempts.question.${values.buyerOrSeller}`)}
                          </FormLegend>
                        </Grid>
                        <Grid item xs={12}>
                          <ValidatorSelectField
                            label={translate(`${product.name}.numberRepairAttempts.label`)}
                            name="numberRepairAttempts"
                            validators={[requiredValidator("Bitte geben Sie die Anzahl der Ausbesserungsversuche an")]}
                          >
                            <MenuItem value="0">{translate(`${product.name}.numberRepairAttempts.values.0`)}</MenuItem>
                            <MenuItem value="1">{translate(`${product.name}.numberRepairAttempts.values.1`)}</MenuItem>
                            <MenuItem value="2">{translate(`${product.name}.numberRepairAttempts.values.2`)}</MenuItem>
                            <MenuItem value="3">{translate(`${product.name}.numberRepairAttempts.values.3`)}</MenuItem>
                          </ValidatorSelectField>
                        </Grid>
                        <Grid item xs={12} className={classNames([classes.gridTopSpaced, classes.question])}>
                          <FormLegend>
                            {translate(`${product.name}.numberReplacementAttempts.question.${values.buyerOrSeller}`)}
                          </FormLegend>
                        </Grid>
                        <Grid item xs={12}>
                          <ValidatorSelectField
                            label={translate(`${product.name}.numberReplacementAttempts.label`)}
                            name="numberReplacementAttempts"
                            validators={[requiredValidator("Bitte geben Sie die Anzahl der Neulieferungsversuche an")]}
                          >
                            <MenuItem value="0">
                              {translate(`${product.name}.numberReplacementAttempts.values.0`)}
                            </MenuItem>
                            <MenuItem value="1">
                              {translate(`${product.name}.numberReplacementAttempts.values.1`)}
                            </MenuItem>
                            <MenuItem value="2">
                              {translate(`${product.name}.numberReplacementAttempts.values.2`)}
                            </MenuItem>
                            <MenuItem value="3">
                              {translate(`${product.name}.numberReplacementAttempts.values.3`)}
                            </MenuItem>
                          </ValidatorSelectField>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}
