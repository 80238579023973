import React from "react";
import { Helmet } from "react-helmet";
import HeadlineSection from "../../../../components/Sections/Generic/HeadlineSection/HeadlineSection";
import GreyBackgroundWrapper from "../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import Layout from "../../../Layouts/Layout";
import ServiceworldStepSection from "../../../../components/Sections/Generic/ServiceworldStepSection/ServiceworldStepSection";
import ContactSection from "../../../../components/Sections/Generic/ContactSection/ContactSection";
import contactImage from "../../../../assets/img/teaser/contact/Contact.svg";

export default function ContactPage({}) {
  const title = "Kontakt | Legalbird";

  return (
    <Layout backgroundType={"landingpage"}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <HeadlineSection headline={"Ihr Kontakt zu uns"} typographyVariant={"h1"} image={contactImage}/>
      <GreyBackgroundWrapper>
        <ServiceworldStepSection />
      </GreyBackgroundWrapper>
      <ContactSection />
    </Layout>
  );
}
