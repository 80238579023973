export const getCaseReferenceNumberRegEx = (insurer) => {
  if (!insurer) {
    return ".*";
  }
  switch (insurer.id) {
    case 2 /* Advocard */:
      return "^\\d{2}-?(33-?0001-?|\\d)\\d{5}-?\\d$";
    case 6 /* ARAG */:
      return "^3100\\d{10}";
    default:
      return "^[a-zA-Z0-9-]{4,30}";
  }
};

export const getInsuranceNumberRegEx = (insurer) => {
  if (!insurer) {
    return ".*";
  }
  switch (insurer.id) {
    case 2 /* Advocard */:
      return "^((20|68|69|50|55|56|79)\\.?)?(([2345679]{1})\\.?\\d{3}\\.?\\d{3}\\.?\\d)(\\-?\\d{2})?$";
    default:
      return ".*";
  }
}