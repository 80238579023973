import loopImage from "../../../../assets/img/teaser/contact/Loop_plane.svg";

const contactSectionStyle = theme => ({
  centerText: {
    textAlign: "center",
  },
  button: {
    margin: "0 auto",
    width: 300,
  },
  buttonBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      width: 350,
      height: 150,
      backgroundImage: "url(" + loopImage + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top right",
      backgroundSize: "80px 50px",
    },
    width: 720,
    height: 180,
    backgroundImage: "url(" + loopImage + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right",
    backgroundSize: "250px 100px",
  },
});

export default contactSectionStyle;
