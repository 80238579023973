import _isString from "lodash/isString";
import jwtDecode from "jwt-decode";

export const checkToken = (token, userState = null) => {
  if (!_isString(token) || token.length === 0 || !userState) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    return (decodedToken.username === userState.email || decodedToken.roles.includes("ROLE_ADMIN")) && decodedToken.exp > Date.now() / 1000;
  } catch (err) {
    return false;
  }
};

export const isAdmin = (token) => {
  if (!_isString(token) || token.length === 0) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp > Date.now() / 1000 && decodedToken.roles.includes("ROLE_ADMIN");
  } catch (err) {
    return false;
  }
};