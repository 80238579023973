import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import wizardStyle from "../wizardStyle";
import Paper from "@mui/material/Paper";
import FormFader from "../Common/Fader/FormFader";
import classNames from "classnames";
import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import FormSubmit from "../Common/FormSubmit/FormSubmit";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import { requiredValidator } from "../../../services/validationRules";
import Typography from "@mui/material/Typography";
import FormLegend from "../Common/FormLegend/FormLegend";

const useStyles = makeStyles(wizardStyle);
export default function ContractDescription({ submitProps }) {
  const classes = useStyles();
  const { handleSubmit } = useForm();
  return (
    <Paper className={classes.paper} elevation={4}>
      <FormFader>
        <form className={classNames(classes.applicationForm, classes.wizardForm)} onSubmit={handleSubmit}>
          <Hidden smUp>
            <Typography variant={"h4"} className={classes.headline}>
              Ihr Vertrag
            </Typography>
          </Hidden>
          <Grid container justifyContent={"flex-start"} alignItems={"baseline"}>
            <Grid item xs={12}>
              <FormLegend>
                <strong>Beschreibung der Situation</strong>
              </FormLegend>
            </Grid>
            <Grid item xs={12} className={classes.gridTopSpaced}>
              <FormLegend>
                Bitte schildern Sie hier kurz Ihre Situation und Fragen. Beschreiben Sie, was genau passiert ist. Den
                  Schriftverkehr mit dem Vertragspartner können Sie auf der folgenden Seite gesondert hochladen. Jede
                  Information hilft uns dabei, Ihren Fall besser einzuschätzen.
              </FormLegend>
            </Grid>
            <Grid item xs={12}>
              <ValidatorTextField
                name="personalTextMessage"
                label="Ihre Situation und Fragen"
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                rows={10}
                validators={[requiredValidator("Bitte schildern Sie uns hier kurz Ihre Situation und Ihre Fragen.")]}
              />
            </Grid>
          </Grid>
          <FormSubmit {...submitProps} />
        </form>
      </FormFader>
    </Paper>
  );
}
