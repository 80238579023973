import React, { useState } from "react";
import * as PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import navigationMenuMobileStyle from "./navigationMenuMobileStyle";
import { Collapse, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore, Menu as MenuIcon } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DialogModal from "../../../DialogModal/DialogModal";
import * as messagesActions from "../../../../store/messages/actions";
import { logout } from "../../../../store/login/actions";
import Logo from "../../Logo/Logo";
import { navigationLinks } from "../../../../services/layoutService";
import _map from "lodash/map";
import LoginAction from "../../../Login/LoginAction";
import { modalview, pageview } from "../../../../services/useTracker";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";

const useStyles = makeStyles(navigationMenuMobileStyle);

export default function NavigationMenuMobile({ clickableLogo }) {
  const classes = useStyles();
  const { customer, isLoggedIn } = useCustomer();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginLayerTitle, setLoginLayerTitle] = useState("Anmelden");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState("");

  const handleCloseLoginLayer = () => {
    navigate(-1);
    pageview(location.pathname);
  };

  const handleOpenLoginLayer = () => {
    setDrawerOpen(false);
    navigate(location.pathname, { state: { loginModalOpen: "loginIcon" } });
    modalview("Login Layer Modal");
  };

  const toggleSubMenu = (subListName) => {
    if (subMenuOpen === subListName) {
      setSubMenuOpen("");
      return;
    }
    setSubMenuOpen(subListName);
  };

  const closeMenu = () => {
    setSubMenuOpen("");
    setDrawerOpen(false);
  };

  const triggerVerifyPrompt = () => {
    messagesActions.addMessage({
      type: "info",
      text: "Bitte bestätigen Sie Ihre E-Mail-Adresse, damit Sie alle Funktionen der Servicewelt nutzen können.",
    });
  };

  const handleLogout = () => {
    logout();
    closeMenu();
    navigate("/");
  };

  const LinkOrVerifiyPromptButton = ({ label, link, verification }) => {
    if (verification) {
      return (
        <ListItemButton component={Link} to={link}>
          <ListItemText>{label}</ListItemText>
        </ListItemButton>
      );
    }

    return (
      <ListItemButton onClick={() => triggerVerifyPrompt()}>
        <ListItemText>{label}</ListItemText>
      </ListItemButton>
    );
  };

  return (
    <>
      <IconButton onClick={() => setDrawerOpen(true)} size="large">
        <MenuIcon />
      </IconButton>

      <Drawer anchor={"top"} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.logoContainer}>
          <Logo target={isLoggedIn ? "/servicewelt" : "/"} isClickable={clickableLogo} />
        </div>
        <List>
          <ListItemButton onClick={() => toggleSubMenu("areas")}>
            <ListItemText>Rechtsbereiche</ListItemText>
            <ListItemIcon>{subMenuOpen === "areas" ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          </ListItemButton>
          <Collapse in={subMenuOpen === "areas"}>
            <List className={classes.subMenu}>
              {_map(navigationLinks.areas, (link, index) => (
                <ListItemButton key={index} component={Link} to={link.to} onClick={() => setDrawerOpen(false)}>
                  <ListItemText>{link.label}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          <ListItemButton onClick={() => toggleSubMenu("guidebooks")}>
            <ListItemText>Ratgeber</ListItemText>
            <ListItemIcon>{subMenuOpen === "guidebooks" ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          </ListItemButton>
          <Collapse in={subMenuOpen === "guidebooks"}>
            <List className={classes.subMenu}>
              {_map(navigationLinks.guidebooks, (link, index) => (
                <ListItemButton key={index} component={Link} to={link.to} onClick={() => setDrawerOpen(false)}>
                  <ListItemText>{link.label}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          <ListItemButton onClick={() => toggleSubMenu("about-us")}>
            <ListItemText>Über Legalbird</ListItemText>
            <ListItemIcon>{subMenuOpen === "about-us" ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          </ListItemButton>
          <Collapse in={subMenuOpen === "about-us"}>
            <List className={classes.subMenu}>
              {_map(navigationLinks.aboutUs, (link, index) => (
                <ListItemButton key={index} component={Link} to={link.to} onClick={() => setDrawerOpen(false)}>
                  <ListItemText>{link.label}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
        <Divider />
        <List>
          {isLoggedIn ? (
            <>
              <LinkOrVerifiyPromptButton verification={customer.verification} link={"/servicewelt"} label={"Meine Servicewelt"} />
              <LinkOrVerifiyPromptButton verification={customer.verification} link={"/servicewelt/mein-konto"} label={"Mein Konto"} />
              <ListItemButton onClick={handleLogout}>
                <ListItemText>Abmelden</ListItemText>
              </ListItemButton>
            </>
          ) : (
            <ListItemButton onClick={handleOpenLoginLayer}>
              <ListItemText>Anmelden</ListItemText>
            </ListItemButton>
          )}
        </List>
      </Drawer>
      <DialogModal open={!!(location.state && location.state.loginModalOpen === "loginIcon")} onClose={handleCloseLoginLayer} title={loginLayerTitle}>
        <LoginAction loginLayerTitle={setLoginLayerTitle} />
      </DialogModal>
    </>
  );
}

NavigationMenuMobile.propTypes = {
  isLoggedIn: PropTypes.bool,
};
